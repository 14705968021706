import { Html5Qrcode } from 'html5-qrcode'
import { useEffect, useState } from 'react'
import { Button, Table } from 'flowbite-react'
import axios from 'axios'
import NavigationComponent from './Nav_footer/Navigation'
import Messagepopup from './Popup/Messagepopup'

export default function QRScannerComponent() {
	const [Stop, setStop] = useState(false)
	const [Message, setMessage] = useState('')
	const [AttendanceArray, setAttendanceArray] = useState(
		JSON.parse(localStorage.getItem('RcnIdList')) || []
	)
	let array = JSON.parse(localStorage.getItem('array')) || []
	let RcnIdList = JSON.parse(localStorage.getItem('RcnIdList')) || []
	console.log(array)
	useEffect(() => {
		const html5QrCode = new Html5Qrcode('reader')

		const qrCodeSuccessCallback = (decodedText, decodedResult) => {
			Stop &&
				html5QrCode.stop().catch((err) => {
					console.log(err)
				})
			const data = JSON.parse(decodedResult.decodedText)
			const RcnId = data.RcnId
			const UserId = data.UserId
			if (
				UserId !== null &&
				UserId !== undefined &&
				!array.includes(UserId)
			) {
				alert(RcnId)
				array.push(UserId)
				console.log(array)
				RcnIdList.push(RcnId)
				setAttendanceArray(RcnIdList)
				localStorage.setItem('array', JSON.stringify(array))
				localStorage.setItem(
					'RcnIdList',
					JSON.stringify(RcnIdList)
				)
			} else {
				alert('Attendance already taken')
			}
		}
		const config = { fps: 30, qrbox: { width: 150, height: 150 } }
		html5QrCode.start(
			{ facingMode: 'environment' },
			config,
			qrCodeSuccessCallback
		)
	}, [])

	const sendAttendace = (e) => {
		e.preventDefault()
		console.log(array)
		axios
			.post(
				`${process.env.REACT_APP_SERVER_URL}/api/attendance/submitAttendance`,
				{
					AttendanceList: array,
				},
				{ withCredentials: true }
			)
			.then((result) => {
				if (result.data.message === 'success') {
					setMessage('Attandance Marked')
					setTimeout(() => {
						setMessage('')
					}, 2000)
					localStorage.clear()
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	return (
		<>
			<NavigationComponent />
			{!Stop && <h2 className="text-center">Scan QRCode</h2>}
			<div id="reader" className="w-64 m-auto rounded mb-5"></div>
			<div className="flex flex-col w-fit m-auto">
				<Button onClick={sendAttendace}>Mark attendance</Button>
				<Button
					className="bg-red-600 mt-10"
					onClick={() => {
						localStorage.clear()
						window.location.reload()
					}}
				>
					Clear attendance list
				</Button>
			</div>

			<Table hoverable>
				<Table.Head>
					<Table.HeadCell>Sl.No</Table.HeadCell>
					<Table.HeadCell>RCN ID</Table.HeadCell>
				</Table.Head>
				<Table.Body>
					{AttendanceArray.map((element, index) => {
						return (
							<Table.Row>
								<Table.Cell>{index + 1}</Table.Cell>
								<Table.Cell>{element}</Table.Cell>
							</Table.Row>
						)
					})}
				</Table.Body>
			</Table>
			{Message && <Messagepopup message={Message} />}
		</>
	)
}
