import React from "react"

export default function FooterComponent() {
    return (
        <>
            <footer className=" relative bottom-0 text-center p-8  w-full ">
                <p> copyright reserved &copy; RoboticsClub</p>
                <p className="text-xs"> Created by <a className="text-blue-800" target="_blank" href="https://github.com/Taksheelshetty">Taksheel Shetty</a> and <a className="text-blue-800" target="_blank" href="https://github.com/SrivatsaRUpadhya">Srivatsa R Upadhya</a></p>
            </footer>
        </>
    )
}
