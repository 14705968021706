import { Modal, Label, TextInput, Button, Checkbox ,Alert} from 'flowbite-react';
import React from 'react';





export default function Alertcomponent() {
    return (<>
        <Alert
            color="success"
            rounded={false}
            withBorderAccent={true}
            onDismiss={true}
            additionalContent={
            <React.Fragment>
                <div className="mt-2 mb-4 text-base/4 text-green-700 dark:text-green-800">We would like to inform you that not all functions are currently available on the website. However, we will be updating the website periodically, and all functions will be made available in a phased manner. </div>
                <div className="flex">
                    {/* <button type="button" className="mr-2 inline-flex items-center rounded-lg bg-green-700 px-3 py-1.5 text-center text-xs font-medium text-white hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-green-800 dark:hover:bg-green-900">View more</button> */}
                    {/* <button type="button" className="rounded-lg border border-green-700 bg-transparent px-3 py-1.5 text-center text-xs font-medium text-green-700 hover:bg-green-800 hover:text-white focus:ring-4 focus:ring-green-300 dark:border-green-800 dark:text-green-800 dark:hover:text-white">Dismiss</button> */}
                    </div>
                    </React.Fragment>}
         
        >
            <h3 className="text-lg font-medium text-green-700 dark:text-green-800">
                Alert
            </h3>
        </Alert></>)
}