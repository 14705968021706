import { Button, Table } from "flowbite-react";
import { useEffect, useState } from "react";


export default function ExpenseTable({ data }) {

    return (
        <>
            <div className=" overflow-x-auto">
                <Table hoverable={true}>
                    <Table.Head>
                        <Table.HeadCell>
                            Component Name
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Status
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Category
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Price
                        </Table.HeadCell>
                        {/* {data.mode === "edit" &&<Table.HeadCell>
                            <span className="sr-only">
                                Edit
                            </span>
                        </Table.HeadCell>} */}
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {data.List.map((element, index) => {
                            return (
                                <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        {(JSON.parse(element.desc)).map((item, index) => `${item.Name} : ${item.Price} \n`)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {element.status}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {element.catagory}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {element.price}
                                    </Table.Cell>
                                    {/* {data.mode === "edit" && <Table.Cell>
                                        <a
                                            href="/tables"
                                            className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                                        >
                                            Edit
                                        </a>
                                    </Table.Cell>} */}
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </div>
        </>
    )
}