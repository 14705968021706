import React, { useContext, useState } from 'react'
import { Card, Dropdown, Button } from 'flowbite-react'
import Messagepopup from '../Popup/Messagepopup'
import axios from 'axios'
import { UserContext } from '../../App'
// import { InformationCircleIcon } from "@heroicons/24/outline";

export default function EventCard({ props }) {
	const [Message, setMessage] = useState('')
	const [showDiscription, setshowDiscription] = useState('true')
        const user = useContext(UserContext);
	const registerForEvent = () => {
		axios
			.post(
				`${process.env.REACT_APP_SERVER_URL}/api/events/register`,
				 { EventID: props.eventData.eventID },

				{ withCredentials: true }
			)
			.then((res) => {
				if (res.data.message === 'success') {
					setMessage('Registered')
					setTimeout(() => {
						window.location.reload()
					}, 1000)
				} else {
					setMessage(res.data.message)
					setTimeout(() => {
						setMessage('')
					}, 1000)
				}
			})
			.catch((err) => {
				setMessage('Error')
				setTimeout(() => {
					setMessage('')
				}, 1000)
			})
	}
	const downloadUserList = () => {
		axios
			.post(
				`${process.env.REACT_APP_SERVER_URL}/api/events/UserListbyEvent`,
				{ EventId: props.eventData.eventID },
				{ withCredentials: true, responseType: 'blob' }
			)
			.then((result) => {
				//Handle success
				const url = window.URL.createObjectURL(new Blob([result.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute(
					'download',
					`${props.eventData.eventName}.csv`
				)
				document.body.appendChild(link)
				link.click()
			})
			.catch((error) => {
				setMessage('Error')
				setTimeout(() => {
					setMessage('')
				}, 1000)
			})
	}

	const downloadResources = () => {
		axios
			.post(
				`${process.env.REACT_APP_SERVER_URL}/api/drive/listFiles`,
				{ WorkshopName: props.eventData.eventName },
				{ withCredentials: true }
			)
			.then((result) => {
				//Handle success
				window
					.open(result.data.data.files[0]?.webViewLink, '_blank')
					.focus()
			})
			.catch((error) => {})
	}

	return (
		<Card className="h-96 shadow-md rounded-lg mt-auto transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-90 duration-300 ">
			{showDiscription === 'true' && (
				<div className="flex justify-end px-4 pt-4">
					<Dropdown inline={true} label="">
						<Dropdown.Item>
							<button
								onClick={downloadResources}
								href="#"
								className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
							>
								Resources
							</button>
							{user.Permissions === 'ADMIN' ||
						user.Permissions === 'EVENTS' ||
						user.Permissions === 'SUPERUSER' && <button
								href="#"
								className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
								onClick={downloadUserList}
							>
								Download Users List
							</button>}
						</Dropdown.Item>
					</Dropdown>
				</div>
			)}
			<div className="flex flex-col items-center pb-10  m-8 sm:pb-5">
				<img
					className="mb-3 h-24 w-24 rounded-lg shadow-lg"
					src="./Inventory.jpg"
					alt=""
				/>
				{showDiscription === 'true' ? (
					<div>
						<h5 className="text-xl text-center font-medium text-gray-900  dark:text-gray-400 ">
							{props.eventData.eventName}
						</h5>
						<h5 className="text-sm text-gray-500 dark:text-gray-400 text-center">
							{props.eventData.catagory}
						</h5>
						<h5 className="text-sm text-gray-500 dark:text-gray-400 text-center">
							{props.eventData.eventDate.split('T')[0]}
						</h5>
						<div className="mt-4 flex items-center space-x-3 lg:mt-6">
							{!props.RegisterdEvents.includes(
								props.eventData.eventID
							) ? (
								<button
									className="inline-flex items-center rounded-lg bg-cyan-700 px-4 py-2 text-center text-sm font-medium text-white hover:bg-cyan-800 focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
									onClick={registerForEvent}
								>
									REGISTER
								</button>
							) : (
								<a
									className="m-auto items-center rounded-lg bg-cyan-700 px-4 py-2 text-center text-sm font-medium text-white hover:bg-cyan-800 focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
									href="/resources"
								>
									VIEW
								</a>
							)}
							{!props.RegisterdEvents.includes(
								props.eventData.eventID
							) && (
								<button
									className="inline-flex items-center m-auto rounded-lg content-center bg-cyan-700 px-4 py-2 text-center text-sm font-medium text-white hover:bg-cyan-800 focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
									onClick={() => setshowDiscription('false')}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										className="bi bi-info-circle-fill"
										viewBox="0 0 16 16"
									>
										<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
									</svg>
								</button>
							)}
						</div>
					</div>
				) : (
					<div>
						<h5 className="text-xl text-center font-medium text-gray-900">
							DISCRIPTION
						</h5>
						<div>
							<h5 className="text-sm text-gray-500 dark:text-gray-400 text-center overflow-y-auto max-h-36">
								{props.eventData.desc}
							</h5>
						</div>
						<div className="mt-4 flex  flex-col space-x-3 lg:mt-6 sticky">
							<button
								className=" sticky inline-flex items-center m-auto rounded-lg bg-cyan-700 px-4 py-2 text-center text-sm font-medium text-white hover:bg-cyan-800 focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
								onClick={() => setshowDiscription('true')}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									width="16"
									height="16"
									viewBox="0,0,256,256"
								>
									<g
										fill="#FFFFFF"
										fill-rule="nonzero"
										stroke="none"
										stroke-width="1"
										stroke-linecap="butt"
										stroke-linejoin="miter"
										stroke-miterlimit="10"
										stroke-dasharray=""
										stroke-dashoffset="0"
										font-family="none"
										font-weight="none"
										font-size="none"
										text-anchor="none"
									>
										<g transform="translate(0,256) rotate(-90) scale(5.12,5.12)">
											<path d="M20,4c-4.94531,0 -9,4.05469 -9,9v22.5625l-5.28125,-5.28125l-1.4375,1.4375l7,7l0.71875,0.6875l0.71875,-0.6875l7,-7l-1.4375,-1.4375l-5.28125,5.28125v-22.5625c0,-3.85547 3.14453,-7 7,-7h11v-2zM38,10.59375l-0.71875,0.6875l-7,7l1.4375,1.4375l5.28125,-5.28125v22.5625c0,3.85547 -3.14453,7 -7,7h-11v2h11c4.94531,0 9,-4.05469 9,-9v-22.5625l5.28125,5.28125l1.4375,-1.4375l-7,-7z"></path>
										</g>
									</g>
								</svg>
							</button>
						</div>
					</div>
				)}
			</div>
			{Message && <Messagepopup message={Message}></Messagepopup>}
		</Card>
	)
}
