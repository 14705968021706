import React, { useContext,useState } from "react";
import { Button, Label, TextInput, Modal, Select } from "flowbite-react";
import axios from "axios";
import Messagepopup from "../Popup/Messagepopup";
import { UserContext } from '../../App'

export default function TransactionModal({ props }) {
  const user = useContext(UserContext)
  const [Payment, setPayment] = useState(false);
  const [Message, setMessage] = useState("");
  const [PaymentID, setPaymentID] = useState(null);
  const sendTarnsaction = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/users/setUserInfo`,
        {
          PaymentID,
        },
        { withCredentials: true }
      )
      .then((result) => {
        if (result.data.message === "success") {
          setMessage("Will Update You Soon");
          setTimeout(() => {
            setMessage("");
            window.location.href =("https://roboticsclubnitte.com/");
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <React.Fragment>
        <Modal
          position={"center"}
          show={props.PaymentModal}
          size="md"
          popup={true}
          root={document.body}
          className="fixed top-0 left-0 right-0"
        >
          <Modal.Body>
            <form
              onSubmit={(e) => {
                sendTarnsaction(e);
              }}
            >
              {!Payment && (
                <div className=" font-mono ">
                  <div className="font-semibold p-5">
                    <h3 className="text-xl font-mono text-gray-900 dark:text-white text-center font-extrabold p-3">
                      PAYMENT GATEWAY
                    </h3>

                    <div className="mb-2 p-2">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="Payinfo"
                          value="During registration, please note that an annual membership fee of ₹350 is required to join the NITTE Robotics Club"
                        />
                      </div>
                      <div className="flex flex-col mb-4 m-4 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4 ">
                        <Button
                          outline
                          color="failure"
                          onClick={() => setPayment(true)}
                        >
                          Make Payment
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {Payment && (
                <div>
                  <div className="font-semibold p-5">
                    <h3 className="text-xl font-mono text-gray-900 dark:text-white text-center font-extrabold p-3">
                      PAYMENT GATEWAY
                    </h3>
                    <div className="flex flex-col items-center m-2 sm:pb-5">
                      <img
                        className="mb-3 h-40 w-40 rounded-lg shadow-lg"
                        src="./UpiCode.jpg"
                        alt=""
                      />
                    </div>
                    <div className="p-2">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="Transaction ID"
                          value="Transaction ID"
                        />
                      </div>
                      <div>
                        <TextInput
                          className="max-w-"
                          helperText={<>Not your UPI ID. The transaction refrence ID you get after successful payment..</>}
                          id="Transaction"
                          placeholder="Transaction ID"
                          autoFocus={true}
                          required={true}
                          onChange={(e) => {
                            setPaymentID(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col  mt-3 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4 ">
                      <Button
                        outline
                        className="mb-1"
                        color="failure"
                        onClick={() => {
                          setPayment(false);
                        }}
                      >
                        Previous
                      </Button>

                      <Button outline type="submit">
                        Next
                        <svg
                          class="w-3.5 h-3.5 ml-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 10"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
      {Message && <Messagepopup message={Message}></Messagepopup>}
    </>
  );
}
