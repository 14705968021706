import axios from 'axios'
import { Button, Table } from 'flowbite-react'
import { useContext, useState } from 'react'
import EditEventForm from '../Forms/EditEventForm'
import { UserContext } from '../../App'

export default function EventTable({ data }) {
	const [EventID, setEventID] = useState('')
	const [EventName, setEventName] = useState('')
	const [Catagory, setCatagory] = useState('')
	const [EventDate, setEventDate] = useState('')
	const [Desc, setDesc] = useState('')
	const [MaxEntries, setMaxEntries] = useState('')
	const [ShowEditForm, setShowEditForm] = useState(false)
	const user = useContext(UserContext)
	console.log(user)

	const handleEdit = (e) => {
		setEventID(e.target.dataset.id)
		setEventName(e.target.dataset.name)
		setCatagory(e.target.dataset.catagory)
		setEventDate(e.target.dataset.quantity)
		setDesc(e.target.dataset.quantity)
		setMaxEntries(e.target.dataset.max_entries)
		setShowEditForm(true)
	}
const downloadUserList = (EventId, EventName) => {
		axios
			.post(
				`${process.env.REACT_APP_SERVER_URL}/api/events/UserListbyEvent`,
				{ EventId },
				{ withCredentials: true, responseType: 'blob' }
			)
			.then((result) => {
				//Handle success
				const url = window.URL.createObjectURL(new Blob([result.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${EventName}.csv`)
				document.body.appendChild(link)
				link.click()
			})
			.catch((error) => {})
	}
	return (
		<>
			<div className="overflow-x-auto">
				<Table hoverable={true}>
					<Table.Head>
						<Table.HeadCell>Event Name</Table.HeadCell>
						<Table.HeadCell>Event Date</Table.HeadCell>
						<Table.HeadCell>Category</Table.HeadCell>
						<Table.HeadCell>DESCRIPTION</Table.HeadCell>
						<Table.HeadCell>Max Entries</Table.HeadCell>
						{user.Permissions === 'ADMIN' ||
						user.Permissions === 'EVENTS' ||
						user.Permissions === 'SUPERUSER' ? (
							<Table.HeadCell>Participants</Table.HeadCell>
						) : null}
						{data.mode === 'edit' && (
							<Table.HeadCell>
								<span className="sr-only">EDIT</span>
							</Table.HeadCell>
						)}
					</Table.Head>
					<Table.Body className="divide-y">
						{data.List.length > 0
							? data.List.map((element, index) => {
									return (
										<Table.Row
											key={index}
											className="bg-white dark:border-gray-700 dark:bg-gray-800"
										>
											<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
												{element.eventName}
											</Table.Cell>
											<Table.Cell>
												{
													element.eventDate.split(
														'T'
													)[0]
												}
											</Table.Cell>
											<Table.Cell>
												{element.catagory}
											</Table.Cell>
											<Table.Cell>
												<div className="overflow-y-scroll max-h-32">
													{element.desc}
												</div>
											</Table.Cell>
											<Table.Cell>
												{element.max_entries}
											</Table.Cell>
											{user.Permissions === 'ADMIN' ||
											user.Permissions === 'EVENTS' ||
											user.Permissions === 'SUPERUSER' ? (
												<Table.Cell>
													<p className="font-semibold">
														Total Registrations:{' '}
														{element.users.length}
													</p>
													<div className="overflow-y-scroll max-h-32">
														{element.users.map(
															(user) => {
																return (
																	<div
																		key={
																			index
																		}
																		className="border-b max-h-32 overflow-y-scroll"
																	>
																		Name:{' '}
																		{
																			user.name
																		}
																		<br></br>
																		USN:{' '}
																		{
																			user.usn
																		}
																	</div>
																)
															}
														)}
													</div>
												</Table.Cell>
											) : null}
											{data.mode === 'edit' && (
												<Table.Cell className="text-center">
													<button
														data-id={
															element.eventID
														}
														data-name={
															element.eventName
														}
														data-catagory={
															element.catagory
														}
														data-quantity={
															element.eventDate.split(
																'T'
															)[0]
														}
														data-desc={element.desc}
														data-max_entries={
															element.max_entries
														}
														onClick={(e) => {
															handleEdit(e)
														}}
														className="font-medium text-blue-600 hover:underline dark:text-blue-500 p-5"
													>
														Edit
													</button>
													<button className="font-medium text-blue-600 hover:underline dark:text-blue-500 p-5" onClick={()=>downloadUserList(element.eventID, element.eventName)}>
														Participants List
													</button>
												</Table.Cell>
											)}
										</Table.Row>
									)
							  })
							: 'No Events'}
					</Table.Body>
				</Table>
				{ShowEditForm && (
					<EditEventForm
						props={{
							data: {
								EventID,
								EventName,
								Catagory,
								EventDate,
								Desc,
								MaxEntries,
							},
							setShowEditForm,
							ShowEditForm,
						}}
					/>
				)}
			</div>
		</>
	)
}
