import React from "react";
import { useState } from "react";
import { Button } from "flowbite-react";
import NavigationComponent from "../components/Nav_footer/Navigation";
import axios from "axios";
import FooterComponent from "../components/Nav_footer/Footer";
import { useParams } from "react-router-dom";

export default function DocumentationPage() {
    const params = useParams()
    const mode = params.mode
    const [ShowUploadForm, setShowUploadForm] = useState(false);
    const [List, setList] = useState([]);
    console.log(mode)

    const checkAuth = () => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/inventory/listInventory`, {}, { withCredentials: true })
            .then(result => {
                if (result.status === 200 && result.data.message === "success") {
                    setList(result.data.expenses)
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    window.location.replace("/login")
                }
                if (error.response.status === 403) {
                    window.location.replace("/")
                }
                console.log(error)
            })
    }
    return (
        <>
            {/* {ShowUploadForm && <DocumentForm props={{ ShowUploadForm, setShowUploadForm, List, setList}} />} */}
            <NavigationComponent />
            {/* <div className="mt-8 my-5">

                <div className="text-center"> <h1 className="font-bold mb-10">Document Table</h1></div>
                <DocumentTable data={{ List,mode }} />
                <div>
                    <div className="flex gap-3 w-fit m-auto mt-10">

                        <div className="items-center m-1" >
                            {mode === "edit" &&<Button 
                            // disabled= {mode}
                            onClick={() => setShowUploadForm(true)}>
                                Add
                            </Button>}

                        </div>
                    </div>
                </div>

            </div> */}
            <div className="text-center mt-[10%] p-24 text-3xl">Under Developement!</div>
            <div className="relative bottom-0 text-center p-8  w-full">
                <FooterComponent />
            </div>

        </>
    )
}