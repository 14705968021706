import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { Tabs } from 'flowbite-react'
import EventCard from './Cards/EventCard'
import Profilecomponent from './Cards/Profile'
import Alertcomponent from './Popup/Alert'
import DashboardCard from './Cards/DasboardCard'
import { MdDashboard } from 'react-icons/md'
import { HiClipboardList, HiUserCircle } from 'react-icons/hi'
import axios from 'axios'
import { UserContext } from '../App'
import Messagepopup from './Popup/Messagepopup'

export default function Dashboard() {
	const [RegisterdEvents, setRegisterdEvents] = useState([])
	const [List, setList] = useState([])
	const [Message, setMessage] = useState('')
	const user = useContext(UserContext)
	const [Access, setAccess] = useState(user?.Permissions)

	useEffect(() => {
		user.Events &&
			user.Events.map((event) => {
				RegisterdEvents.push(event.eventID)
				return setRegisterdEvents([...RegisterdEvents])
			})
	}, [])
	const data = [
		{
			head: 'CERTIFICATES',
			content: 'Download your certificates ',
			img: './Inventory.jpg',
			link: '#',
			link1: '#',
		},
		{
			head: 'INVENTORY',
			content: 'Check the Inventory table by clicking on view',
			img: './Inventory.jpg',
			link: '/inventory/edit',
			link1: '/inventory/view',
		},
		{
			head: 'DOCUMENTS',
			content: 'View the Documents by clicking on view',
			img: './Docimage.jpg',
			link: '/documents/edit',
			link1: '/documents/view',
		},
		{
			head: 'EVENTS',
			content: 'Update events by clicking on edit',
			img: './event.jpg',
			link: '/events/edit',
			link1: '/events/view',
		},
		{
			head: 'EXPENSES',
			content: 'Check your Expense table by clicking on view',
			img: './Expensee.jpg',
			link: '/expenses/edit',
			link1: '/expenses/view',
		},
		{
			head: 'IOT',
			content: 'Check your iot inventory table by clicking on view',
			img: './Inventory.jpg',
			link: '/iot/edit',
			link1: '/iot/view',
		},
	]

	useEffect(() => {
		axios
			.post(
				`${process.env.REACT_APP_SERVER_URL}/api/events/getAllEvents`,
				{},
				{ withCredentials: true }
			)
			.then((result) => {
				if (
					result.status === 200 &&
					result.data.message === 'success'
				) {
					setList(result.data.data)
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					return (window.location.href = '/register')
				}
				if (err.response.status === 403) {
					return (window.location.href = '/')
				}
				console.log(err)
			})

		if (user.isProfileComplete) {
			if (user.PaymentStatus !== 'RECEIVED') {
				setMessage('Your Paymet Is Not Yet Verified!')
				setTimeout(() => {
					setMessage('')
					window.location.replace(process.env.REACT_APP_HOME_URL)
				}, 3000)
			}
		} else {
			window.location.replace('/register')
		}
	}, [])

	return (
		<>
			<Tabs.Group aria-label="Tabs with icons" style={'default'}>
				<Tabs.Item title="Profile" icon={HiUserCircle}>
					<div className="m-5 justify-center">
						<Profilecomponent></Profilecomponent>
					</div>
					<div className="container">
						<Alertcomponent></Alertcomponent>
					</div>
				</Tabs.Item>
				<Tabs.Item title="Dashboard" icon={MdDashboard} active={true}>
					<div className="my-4">
						<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-6 gap-3">
							{data.map((element, index) => (
								<div key={index}>
									<DashboardCard
										props={{
											head: element.head,
											content: element.content,
											link: element.link,
											img: element.img,
											link1: element.link1,
											Access,
										}}
									></DashboardCard>
								</div>
							))}
						</div>
					</div>
				</Tabs.Item>
				<Tabs.Item title="Events" icon={HiClipboardList}>
					{' '}
					<div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 2xl::grid-cols-6 gap-3">
						{List.length > 0 ? (
							List.map((element, index) => {
								return (
									<div key={index}>
										<EventCard
											props={{
												eventData: element,
												RegisterdEvents,
											}}
										></EventCard>
									</div>
								)
							})
						) : (
							<div className="items-center text-center">
								No Events
							</div>
						)}
					</div>
				</Tabs.Item>
			</Tabs.Group>
			{Message && <Messagepopup message={Message}></Messagepopup>}
		</>
	)
}
