import React, { useState } from "react"
import { Button, Card, Dropdown } from "flowbite-react"
import PasswordPrompt from "../Forms/PasswordPrompt"


export default function DasboardCard({ props }) {
    const [showEditModal, setshowEditModal] = useState(false);
    const editlink = props.link
    return (<>

        {props.Access === "NO_EDIT_ACCESS" ? props.head === "CERTIFICATES" ? (
            <div className="min-w-fit mt-auto transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-90 duration-300">

                <Card
                >
                    <div className="flex flex-col items-center pb-10 rounded-sm ">
                        <img
                            className="mb-3 h-24 w-24 rounded-lg shadow-lg"
                            src={"./Inventory.jpg"}
                            alt=""
                        />
                        <h5 className="mb-1 text-xl font-bold text-gray-900 dark:text-white">
                            CETIFICATES
                        </h5>
                        <span className=" text-center text-sm text-gray-500 dark:text-gray-400">
                            Check out your cetificates
                        </span>


                        <div className="flex flex-col-2 mt-4 space-x-3 lg:mt-6 ">
                            <a href={"#"}
                                className="inline-flex items-center rounded-lg bg-blue-700 py-2 px-4 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"

                            >
                                view
                            </a>
                            {showEditModal && <PasswordPrompt props={{ showEditModal, setshowEditModal, editlink: "#" }} />}
                        </div>
                    </div>
                </Card>
            </div>) : (null) : (
            <div className="min-w-fit mt-auto transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-90 duration-300">

                <Card
                >
                    <div className="flex flex-col items-center pb-10 rounded-sm ">
                        <img
                            className="mb-3 h-24 w-24 rounded-lg shadow-lg"
                            src={props.img}
                            alt=""
                        />
                        <h5 className="mb-1 text-xl font-bold text-gray-900 dark:text-white">
                            {props.head}
                        </h5>
                        <span className=" text-center text-sm text-gray-500 dark:text-gray-400">
                            {props.content}
                        </span>


                        <div className="flex flex-col-2 mt-4 space-x-3 lg:mt-6 ">
                            <a href={props.link1}
                                className="inline-flex items-center rounded-lg bg-blue-700 py-2 px-4 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"

                            >
                                view
                            </a>
                            {(props.Access === props.head || props.Access === "ADMIN" || props.Access === "SUPERUSER") && <button
                                className="inline-flex items-center rounded-lg bg-blue-700 py-2 px-4 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                onClick={() => {setshowEditModal(true);document.body.style.overflow = 'unset'}      }
                            >
                                Edit
                            </button>}
                            {showEditModal && <PasswordPrompt props={{ showEditModal, setshowEditModal, editlink }} />}
                        </div>
                    </div>
                </Card>


            </div>)}




    </>)
}
