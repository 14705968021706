import React from "react";
import { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
// import { json, Link } from "react-router-dom";
import { Label, TextInput, Button, Modal, Spinner } from "flowbite-react";




export default function Messagepopup({ message }) {
    return (

        <>

            <React.Fragment>
                <Modal
                    show={true}
                    size="sm"
                    popup={true}

                >
                    <Modal.Body className="pt-10">
                        <Modal.Body >
                            <div className="text-center m-auto p-auto">
                                <div> <span id="writters" class=" text-2xl bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500 justify-center"> {message}
                               </span></div>
                            </div>
                        </Modal.Body>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        </>
    )
}