import React, { useContext, useEffect, useState } from 'react'
import { Modal, TextInput, Button, Label, Select } from 'flowbite-react'
import axios from 'axios'
import TransactionModal from './TransactionModal'
import Messagepopup from '../Popup/Messagepopup'
import { UserContext } from '../../App'

export default function InformationModal({ props }) {
	const user = useContext(UserContext)
	console.log(user)
	const [count, setcount] = useState(1)
	const [Name, setName] = useState(user?.Name)
	const [USN, setUSN] = useState(user?.Usn)
	const [Department, setDepartment] = useState(user?.Department)
	const [DOB, setDOB] = useState(user?.DOB)
	const [Phone, setPhone] = useState(user?.Phone)
	const [DeptList, setDeptList] = useState([
		<option selected disabled>
			Select Department
		</option>,
	])
	const [EventID, setEventID] = useState(user?.Events[0]?.eventID)
	const [PaymentModal, setPaymentModal] = useState(false)
	const [YearOfStudy, setYearOfStudy] = useState(user?.YearOfStudy)
	const [Message, setMessage] = useState('')
	const [Skills, setSkills] = useState([
		<option selected disabled>
			Select Skills
		</option>,
	])
	const [Events, setEvents] = useState([
		<option selected disabled>
			Select Event
		</option>,
	])
	const [Selectedskill, setSelectedskill] = useState(user?.Skills)

	useEffect(() => {
		console.log(Events)
		axios
			.post(
				`${process.env.REACT_APP_SERVER_URL}/api/users/getDeptList`,
				{},
				{ withCredentials: true }
			)
			.then((result) => {
				if (
					result.status === 200 &&
					result.data.message === 'success'
				) {
					for (const key in result.data.Departments) {
						DeptList.push(<option value={key}>{key?.replace(/_/gi," ")}</option>)
						setDeptList([...DeptList])
					}
				}
			})
			.catch((error) => {
				console.log(error)
			})

		axios
			.post(
				`${process.env.REACT_APP_SERVER_URL}/api/users/getSkillsAndEvents`,
				{},
				{ withCredentials: true }
			)
			.then((result) => {
				if (
					result.status === 200 &&
					result.data.message === 'success'
				) {
					const EventsResult = result.data.data.Events
					const SkillsResult = result.data.data.Skills
					for (const key in SkillsResult) {
						console.log(key)
						Skills.push(<option value={SkillsResult[key]}>{SkillsResult[key]?.replace(/_/ig," ")}</option>)
						setSkills([...Skills])
					}
					EventsResult.map((element) => {
						Events.push(
							<option value={element['eventID']}>
								{element['eventName']}
							</option>
						)
						return setEvents([...Events])
					})
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}, [])

	const sendInfo = (e) => {
		e.preventDefault()
		axios
			.post(
				`${process.env.REACT_APP_SERVER_URL}/api/users/setUserInfo`,
				{
					Name,
					USN,
					Department,
					Skills: Selectedskill,
					YearOfStudy,
					DOB,
					Phone,
				},
				{ withCredentials: true }
			)
			.then((result) => {
				if (result.data.message === 'success') {
					setMessage('Information Updated')
					setTimeout(() => {
						setMessage('')
					}, 2000)
					setPaymentModal(true)
				} else {
					setcount(count - 1)
				}
			})
			.catch((error) => {
				console.log(error)
			})

		axios
			.post(
				`${process.env.REACT_APP_SERVER_URL}/api/events/register`,
				{ EventID },
				{ withCredentials: true }
			)
			.then((res) => {
				if (res.data.message === 'success') {
					console.log('registered')
				} else {
					setMessage(res.data.message)
					setTimeout(() => {
						setMessage('')
					}, 1000)
				}
			})
			.catch((err) => {
				setMessage('Error')
				setTimeout(() => {
					setMessage('')
				}, 1000)
			})
	}
	return (
		<>
			<React.Fragment>
				<Modal
					position={'center'}
					show={props.showInformationModal}
					size="lg"
					popup={true}
					root={document.body}
					className="fixed top-0 left-0 right-0 z-50 "
				>
					<Modal.Body className="border-r-3">
						<div className="space-y-4 xs:px-4 pb-4 sm:pb-6 lg:px-5 xl:pb-5 font-mono ">
							<div className="font-semibold p-5">
								<form
									onSubmit={(e) => {
										sendInfo(e)
									}}
								>
									<h3 className="text-xl font-mono text-gray-900 dark:text-white text-center font-extrabold p-3">
										Personal Details
									</h3>

									{count === 1 && (
										<div>
											<div className="mb-2">
												<div className="mb-2 block">
													<Label
														htmlFor="Name"
														value="Name"
													/>
												</div>
												<TextInput
													id="Name"
													placeholder="Name"
													autoFocus={true}
													defaultValue={Name}
													disabled
													required={true}
													onChange={(e) =>
														setName(e.target.value)
													}
												/>
											</div>
											<div className="mb-2">
												<div className="mb-2 block">
													<Label
														htmlFor="USN"
														value="USN"
													/>
												</div>
												<TextInput
													id="USN"
													placeholder="4NM..."
													defaultValue={USN}
													required={true}
													onChange={(e) =>
														setUSN(e.target.value)
													}
												/>
											</div>
										</div>
									)}
									{count === 2 && (
										<div>
											<div className="mb-2">
												<div className="mb-2 block">
													<Label
														htmlFor="DOB"
														value="Date Of Birth"
													/>
												</div>
												<TextInput
													id="DOB"
													type="date"
													defaultValue={user?.DOB?.split("T")[0]}
													autoFocus={true}
													required={true}
													onChange={(e) =>
														setDOB(e.target.value)
													}
												/>
											</div>
											<div className="mb-2">
												<div className="mb-2 block">
													<Label
														htmlFor="Phone"
														value="Phone Number"
													/>
												</div>
												<TextInput
													id="Phone"
													type="tel"
													placeholder="Phone"
													maxLength={10}
													defaultValue={Phone}
													required={true}
													onChange={(e) =>
														setPhone(e.target.value)
													}
												/>
											</div>
										</div>
									)}

									{count === 3 && (
										<div>
											<div className="mb-2">
												<div className="mb-2 block">
													<Label
														htmlFor="Department"
														value="Select Your Department"
													/>
												</div>
												<Select
													autoFocus={true}
													defaultValue={Department}
													onChange={(e) => {
														setDepartment(
															e.target.value
														)
													}}
												>
													{DeptList}
												</Select>
											</div>
											<div className="mb-2 p-2">
												<div className="mb-2 block">
													<Label
														htmlFor="sem"
														value="Select Your Year of Study"
													/>
												</div>
												<TextInput
													id="semester"
													placeholder="Year"
													required={true}
													max={4}
													min={1}
													defaultValue={
														YearOfStudy
													}
													type={'number'}
													onChange={(e) =>
														setYearOfStudy(
															e.target.value
														)
													}
												/>
											</div>
										</div>
									)}

									{count === 4 && (
										<div className="mb-2 p-2">
											<div className="mb-2 block">
												<div className="mb-2 block">
													<Label
														htmlFor="Domain"
														value="Domain you Want to Work on"
													/>
													<Select
														defaultValue={
															EventID
														}
														onChange={(e) =>
															setEventID(
																e.target.value
															)
														}
													>
														{Events}
													</Select>
												</div>
												<Label
													htmlFor="Domain_Knowledge"
													value="Select the based on familiarity in your domain?"
												/>
												<Select
													defaultValue={Selectedskill}
													onChange={(e) => {
														setSelectedskill(
															e.target.value
														)
													}}
												>
													{Skills}
												</Select>
											</div>
										</div>
									)}
									<div className="flex flex-col mt-8 mb-4 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4 ">
										<Button
											outline
											color="success"
											type={
												count === 5
													? 'submit'
													: 'button'
											}
											onClick={() => {
												count !== 5
													? setcount(count + 1)
													: setcount(count)
											}}
										>
											Next
											<svg
												class="w-3.5 h-3.5 ml-2"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 14 10"
											>
												<path
													stroke="currentColor"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M1 5h12m0 0L9 1m4 4L9 9"
												/>
											</svg>
										</Button>
										{count !== 1 ? (
											<Button
												outline
												onClick={() => {
													setcount(count - 1)
												}}
											>
												Previous
											</Button>
										) : (
											<Button
												outline
												color={'failure'}
												onClick={() => {
													props.setshowInformationModal(
														false
													)
												}}
											>
												Close
											</Button>
										)}
									</div>
								</form>
							</div>
						</div>
						{PaymentModal && (
							<TransactionModal
								props={{ PaymentModal, setPaymentModal }}
							/>
						)}
						{Message && (
							<Messagepopup message={Message}></Messagepopup>
						)}
					</Modal.Body>
				</Modal>
			</React.Fragment>
		</>
	)
}
