import React, { useState, useEffect } from "react";
import { Modal, Select, Button, Label, TextInput } from "flowbite-react";
import axios from "axios";
import Messagepopup from "../Popup/Messagepopup";


export default function EditItemForm({ props }) {
    const [show, setshow] = useState(true)
    const [Message, setMessage] = useState(undefined)
    const [Name, setName] = useState(undefined)
    const [Quantity, setQuantity] = useState(undefined)
    const [Category, setCategory] = useState(undefined)
    const [Categories, setCategories] = useState([<option selected disabled>Select Category</option>])
    const [Remarks, setRemarks] = useState(undefined)
    const [Condition, setCondition] = useState(undefined)

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/inventory/getInventoryCategories`, {}, { withCredentials: true })
            .then(result => {
                if (result.status === 200 && result.data.message === "success") {
                    for (const key in result.data.categories) {
                        Categories.push(<option>{key}</option>)
                        setCategories([
                            ...Categories
                        ])
                    }
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    return window.location.href = "/login"
                }
                if (err.response.status === 403) {
                    return window.location.href = "/"
                }
            })
    }, [])

    const updateItem = (e) => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/inventory/editItemById`, {
            ItemID: props.data.ItemID,
            ItemName: Name,
            Catagory: Category,
            Quantity,
            Remarks,
            Condition
        }, {
            withCredentials: true
        }).then((response) => {
            if (response.status === 200 && response.data.message === "success") {
                setMessage("Item Updated")
                setTimeout(() => {
                    window.location.reload()
                }, 1000);

            }
        }
        ).catch((err) => {
            console.log(err)
            if (err.response.status === 403) {
                return window.location.replace("/")
            }
            if (err.response.status === 401) {
                return window.location.replace("/login")
            }
            setMessage("An error occured")
            setTimeout(() => {
                setMessage("");
            }, 1000)
        })
    }

    const handleDelete = () => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/inventory/deleteItemById`, { itemID: props.data.ItemID }, {
            withCredentials: true
        }).then((response) => {
            if (response.status === 200 && response.data.message === "success") {
                setMessage(response.data.message)
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }
        }
        ).catch((err) => {
            console.log(err)
            if (err.response.status === 403) {
                return window.location.replace("/")
            }
            if (err.response.status === 401) {
                return window.location.replace("/login")
            }
            setMessage("An error occured")
            setTimeout(() => {
                setMessage("");
            }, 1000)
        })
    }

    return (
        <>
            <Modal
                show={show}
                size="lg"
                root={document.body}
                onClose={() => props.setShowEditForm(false)}
            >
                <Modal.Body>
                    <div className="text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to Edit ?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={() => { setshow(false); }}>
                                Yes, I'm sure
                            </Button>
                            <Button color="gray" onClick={() => props.setShowEditForm(false)}>
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={!show}
                size="lg"
                root={document.body}
                onClose={() => props.setShowEditForm(false)}
            >
                <Modal.Header />
                <Modal.Body>
                    <form onSubmit={(e) => { updateItem(e) }}>
                        <div>
                            <h3 className="text-2xl text-black font-semibold dark:text-gray-400 text-center mb-2">
                                EDIT ITEM
                            </h3>
                            <div className="m-auto w-fit bg-slate-100 p-10 rounded-2xl">
                                <h3 className="mb-2">Selected Item:</h3>
                                <div>
                                    <h3>Name: {props.data.ItemName}</h3>
                                    <h3>Category: {props.data.Catagory}</h3>
                                    <h3>Quantity: {props.data.Quantity}</h3>
                                    <h3>Condition: {props.data.condition}</h3>
                                    <h3>Remarks: {props.data.remarks}</h3>
                                </div>
                            </div>
                            <div className="flex justify-center mb-1 mt-2 ">
                                <Button color="failure" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </div>
                            <div className="mb-2">
                                <div className="max-w-md">
                                    <Label
                                        htmlFor="Change component Name"
                                        value="Change component Name"
                                    />
                                </div>
                                <TextInput
                                    id="Component Name"
                                    defaultValue={undefined}
                                    placeholder="Component Name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-2">
                                <div className="max-w-md">
                                    <Label
                                        htmlFor="Component Conditon"
                                        value="Component Conditon"
                                    />
                                </div>
                                <TextInput
                                    id="Component Conditon"
                                    defaultValue={undefined}
                                    placeholder="Component Conditon"
                                    onChange={(e) => setCondition(e.target.value)}
                                />
                            </div>
                            <div className="mb-2">
                                <div className="max-w-md">
                                    <Label
                                        htmlFor="Change Remark"
                                        value="Change Remark"
                                    />
                                </div>
                                <TextInput
                                    id="Change Remark"
                                    defaultValue={undefined}
                                    placeholder="Change Remark"
                                    onChange={(e) => setRemarks(e.target.value)}
                                />
                            </div>

                        </div>

                        <div className="mb-2">
                            <div className="mb-1 block">
                                <Label
                                    htmlFor="Quantity"
                                    value="Change Quantity"
                                />
                            </div>
                            <TextInput
                                id="Quantity"
                                defaultValue={undefined}
                                onChange={(e) => setQuantity(e.target.value)}
                            />
                        </div>
                        <div>
                            <div className="mb-1 block">
                                <Label
                                    htmlFor="Category"
                                    value="Change the Category"
                                />
                                <Select onChange={(e) => setCategory(e.target.value)} defaultValue={undefined}>
                                    {Categories}
                                </Select>
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-center gap-4 m-7">
                                <Button color="failure" type="submit" onClick={() => {
                                 document.body.style.overflow = 'unset';}}
                                >
                                    Save
                                </Button>
                                <Button color="gray" onClick={() => {setshow(false);props.setShowEditForm(false
                                ); document.body.style.overflow = 'unset';}} >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                    {Message && <Messagepopup message={Message} />}
                </Modal.Body>
            </Modal >
        </>
    )
}