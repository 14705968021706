import React, { useState } from "react";
import { Modal, Button, TextInput, Label, FileInput, Table, Select } from "flowbite-react";
import Messagepopup from "../Popup/Messagepopup";
import axios from "axios";

export default function ExpenseFrom({ props }) {
    const [TempList, setTempList] = useState([]);
    const [Name, setName] = useState("")
    const [Catagory, setCatagory] = useState("")
    const [Price, setPrice] = useState("")
    const [Message, setMessage] = useState("")


    const addExpense = () => {
        if (TempList.length == 0) {
            setMessage("Empty TempList!")
            return (
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            );
        }

        //Set loadin true here
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/expenses/addExpenseByUser`, { List: TempList, Catagory }, { withCredentials: true })
            .then((response) => {
                if (response.data.message === "success") {
                    setMessage("Expense Added!")
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                }
            }).catch(error => {
                setMessage("An error occurred!");
                console.log(error);
                setTimeout(() => {
                    setMessage("");
                }, 1000)
            })
    }
    const addToList = (e) => {
        e.preventDefault();
        setTempList([
            ...TempList,
            {
                Name,
                Catagory,
                Price
            }
        ]);
        setMessage("Added");
        setTimeout(() => {
            setMessage("");
        }, 1000);

        document.getElementById('expense-form').reset();
    }

    return (<>
        <React.Fragment>
            <Modal
                show={props.ShowUploadForm}
                size="lg"
                root={document.body}
                onClose={() => props.setShowUploadForm(false)}
            >
                <Modal.Header className="text-center">
                    EXPENSE ENTRY FORM
                </Modal.Header>
                <Modal.Body>
                    <div className="overflow-auto">

                        <Table hoverable>
                            <Table.Head>
                                <Table.HeadCell>
                                    COMPONENT FORM
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    CATEGORY
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    PRICE
                                </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                {TempList.map((element, index) => {
                                    return (
                                        <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                {element.Name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {element.Catagory}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {element.Price}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                    <form id="expense-form" onSubmit={e => { addToList(e) }} className="flex flex-col gap-4">
                        <div>

                            <div>
                                <div className="mb-1 block">
                                    <Label
                                        htmlFor="Catagory"
                                        value="Select Catagory"
                                    />
                                    <Select
                                        id="Catagory"
                                        onChange={(e) => setCatagory(e.target.value)}
                                        required
                                    >
                                        <option defaultChecked disabled>
                                            SELECT THE CATEGORY
                                        </option>
                                        <option >
                                            WARBOT
                                        </option>
                                        <option>
                                            IOT
                                        </option>
                                        <option>
                                            STATIONARY
                                        </option>
                                    </Select>
                                </div>
                                {/* <h6 className="font-semibold">*What did you buy this for.</h6> */}
                            </div>
                            <div className="bg-green-100 p-5 ">
                                <div>
                                    <div className="mb-2 block">
                                        <Label
                                            htmlFor="COMPONENT NAME"
                                            value="COMPONENT NAME"
                                        />
                                    </div>
                                    <TextInput
                                        id="COMPONENT NAME"
                                        placeholder="motors"
                                        shadow={true}
                                        required
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label
                                            htmlFor="PRICE"
                                            value="PRICE"
                                        />
                                    </div>
                                    <TextInput
                                        id="PRICE"
                                        shadow={true}
                                        required
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <Button type="submit">
                            Add
                        </Button>
                        {/* <div id="fileUpload">
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="file"
                                        value="Upload file"
                                    />
                                </div>
                                <FileInput
                                    id="file"
                                    helperText="Select file containing bill photo"
                                    required
                                />
                            </div> */}
                        <Button type="submit" onClick={addExpense}>
                            Submit
                        </Button>
                    </form>
                    {Message && <Messagepopup message={Message} />}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </React.Fragment >

    </>)
}