import React, { useContext } from "react";
import { DarkThemeToggle } from 'flowbite-react';
import NavigationComponent from "../components/Nav_footer/Navigation";
import FooterComponent from "../components/Nav_footer/Footer";
import Dashboard from "../components/Dashboard";
import { UserContext } from "../App";

export default function Home() {
    const user = useContext(UserContext)

    if (user === null) {
        window.location.href = "/register"
    }

    return <>
        <NavigationComponent />
        <main className="min-h-screen container m-auto">
            <Dashboard />
        </main>
        <FooterComponent />
    </>
}
