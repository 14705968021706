import React, { useEffect, useState, useContext } from 'react'
import { Table, Button, TextInput, Label, Card } from 'flowbite-react'
import axios from 'axios'
import EditUserPermission from '../Forms/EditUserPermission'
import { UserContext } from '../../App'
import Messagepopup from '../Popup/Messagepopup'
import { TableCell } from 'flowbite-react/lib/esm/components/Table/TableCell'

export default function UserTable() {
    const [users, setUsers] = useState([])
    const [Key, setKey] = useState('')
    const [UsersToDisplay, setUsersToDisplay] = useState([])
    const [showEditModal, setshowEditModal] = useState(false)
    const [Name, setName] = useState('')
    const [Role, setRole] = useState('')
    const [Usn, setUsn] = useState('')
    const [Email, setEmail] = useState('')
    const [UserID, setUserID] = useState('')
    const [Permission, setPermission] = useState('')
    const [PaymentIds, setPaymentIds] = useState([])
    const [Length, setLength] = useState(null)
    const [ManitenanceMode, setManitenanceMode] = useState(false)
    const [EventLimit, setEventLimit] = useState(null)
    const [Message, setMessage] = useState('')
    const [Limit, setLimit] = useState(null)

    const user = useContext(UserContext)

    useEffect(() => {
        if (Key !== '') {
            if (PaymentIds.includes(Key)) {
                setUsersToDisplay([users[PaymentIds.indexOf(Key)]])
            } else {
                setUsersToDisplay([])
            }
        } else {
            setUsersToDisplay(users)
        }
    }, [Key])

    const handleEdit = (e) => {
        setName(e.target.dataset.name)
        setshowEditModal(true)
        setRole(e.target.dataset.role)
        setUsn(e.target.dataset.usn)
        setEmail(e.target.dataset.email)
        setUserID(e.target.dataset.userid)
        setPermission(e.target.dataset.permission)
    }

    const downloadList = () => {
        axios
            .get(
                `${process.env.REACT_APP_SERVER_URL}/api/users/downloadUsersList`,
                { withCredentials: true, responseType: 'blob' }
            )
            .then((result) => {
                //Handle success
                const url = window.URL.createObjectURL(new Blob([result.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'list.csv')
                document.body.appendChild(link)
                link.click()
            })
            .catch((error) => {})
    }
    const updateMaintenance = () => {
        user?.Permissions === 'SUPERUSER' &&
            axios
                .post(
                    `${process.env.REACT_APP_SERVER_URL}/api/settings/updateSettings`,
                    { MaintenanceMode: !ManitenanceMode },
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (
                        response.status === 200 &&
                        response.data.message === 'success'
                    ) {
                        setMessage('Updated Settings')
                        setTimeout(() => {
                            setMessage('')
                        }, 3000)
                        window.location.reload()
                    }
                })
                .catch((err) => {
                    console.log(err)
                    if (err.response.status === 403) {
                        window.location.replace('/')
                    }
                    if (err.response.status === 401) {
                        window.location.replace('/register')
                    }
                })
    }
    const updateEventLimit = () => {
        user?.Permissions === 'SUPERUSER' || user?.Permissions === 'ADMIN' &&
            axios
                .post(
                    `${process.env.REACT_APP_SERVER_URL}/api/settings/updateSettings`,
                    { EventLimit: Limit },
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (
                        response.status === 200 &&
                        response.data.message === 'success'
                    ) {
                        setMessage('Updated Settings')
                        setTimeout(() => {
                            setMessage('')
                        }, 3000)
                        window.location.reload()
                    }
                })
                .catch((err) => {
                    console.log(err)
                    if (err.response.status === 403) {
                        window.location.replace('/')
                    }
                    if (err.response.status === 401) {
                        window.location.replace('/register')
                    }
                })
    }

    useEffect(() => {
        axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/api/users/usersList`,
                {},
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.data.message === 'success'
                ) {
                    let count = 1
                    setUsers(response.data.data)
                    setUsersToDisplay(response.data.data)
                    response.data.data.map((user) => {
                        PaymentIds.push(user.paymentID)
                        setPaymentIds(PaymentIds)
                        count++
                    })
                    setLength(count)
                }
            })
            .catch((err) => {
                console.log(err)
                if (err.response.status === 403) {
                    window.location.replace('/')
                }
                if (err.response.status === 401) {
                    window.location.replace('/register')
                }
            })
        user?.Permissions === 'SUPERUSER' &&
            axios
                .get(
                    `${process.env.REACT_APP_SERVER_URL}/api/settings/getSettings`,
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (
                        response.status === 200 &&
                        response.data.message === 'success'
                    ) {
                        setManitenanceMode(
                            response.data?.settings?.maintenanceMode
                        )
                        setEventLimit(response.data.settings.EventLimit)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    if (err?.response?.status === 403) {
                        window.location.replace('/')
                    }
                    if (err?.response?.status === 401) {
                        window.location.replace('/register')
                    }
                })
    }, [])

    const deleteAccount = (userID) => {
        axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/api/auth/deleteAccountByUserId`,
                { userID },
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.data.message === 'success'
                ) {
                    setMessage(response.data.message)
                    setTimeout(() => {
                        setMessage('')
                    }, 1000)
                    window.location.reload()
                } else {
                    setMessage(response.data.message)
                    setTimeout(() => {
                        setMessage('')
                    }, 1000)
                }
            })
            .catch((error) => {
                console.log(error)
                setMessage('An error occurred!')
                setTimeout(() => {
                    setMessage('')
                }, 1000)
            })
    }

    const verifyPayment = (userID) => {
        axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/api/users/verifyPayment`,
                { userToVerify: userID },
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.data.message === 'success'
                ) {
                    setUsers(response.data.data)
                    window.location.reload()
                }
            })
            .catch((err) => {
                console.log(err)
                if (err.response.status === 403) {
                    window.location.replace('/')
                }
                if (err.response.status === 401) {
                    window.location.replace('/login')
                }
            })
    }
    return (
        <>
            {/* Setting */}
            <div>
                <div className="grid grid-rows-3 grid-cols-2 gap-3 m-2 ">
                    <div className="row-span-3">
                        <Card className=" h-full p-1 m-1">
                            <div className="grid grid-rows-4 gap-5">
                                <div className="row-span-1 text-center">
                                    <h3>TOTAL MEMBER</h3>
                                </div>
                                <div className="row-span-1 text-center">
                                    <h3>{Length}</h3>
                                </div>
                                <div className="row-span-1 text-center">
                                    <h3>YTCOM</h3>
                                </div>
                                <div className="row-span-1 text-center">
                                    <h3></h3>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="row-span-1">
                        <Card className=" h-full p-1 m-1">
                            <div className="align-middle text-center">
                                <div>
                                    <h3>Supervisor Mode</h3>
                                </div>
                                <div>
                                    <button
                                        onClick={updateMaintenance}
                                        className="inline-flex items-center rounded-lg bg-blue-700 py-2 px-4 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                        {ManitenanceMode
                                            ? 'Turn OFF'
                                            : 'Turn On'}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="row-span-1">
                        <Card className=" h-full p-1 m-1">
                            <div className="align-middle text-center">
                                <div>
                                    <h3>Max Events</h3>
                                </div>
                                <div className="flex">
                                    <TextInput
                                        type="number"
                                        min={0}
                                        onChange={(e) => {
                                            setLimit(e.target.value)
                                        }}
                                    />
                                    <button
                                        onClick={updateEventLimit}
                                        className="inline-flex items-center rounded-lg bg-blue-700 py-2 px-4 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                        Set Limit
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="row-span-1">
                        <Card className=" h-full p-1 m-1">
                            <div className="align-middle text-center">
                                <div>
                                    <button
                                        onClick={downloadList}
                                        className="inline-flex items-center rounded-lg bg-blue-700 py-2 px-4 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                        Download User List
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>

            {/* Setting*/}

            <h3 className="text-xl  text-gray-900 dark:text-white text-center font-mono font-semibold p-6">
                User Name
            </h3>
            <div className="mb-2 block">
                <Label htmlFor="Transaction" value="Transaction id" />
            </div>
            <form>
                <TextInput
                    id="ID"
                    placeholder="Search by Transaction ID"
                    onChange={(e) => {
                        setKey(e.target.value)
                    }}
                />
                <Button
                    className="mt-3 mb-3 align-middle"
                    type="button"
                    onClick={() => {
                        document.getElementById('ID').value = ''
                        setKey('')
                    }}
                >
                    Clear
                </Button>
            </form>

            <div className="overflow-auto">
                <Table hoverable>
                    <Table.Head>
                        <Table.HeadCell>Name</Table.HeadCell>
                        <Table.HeadCell>USN</Table.HeadCell>
                        <Table.HeadCell>Email</Table.HeadCell>
                        <Table.HeadCell>Phone</Table.HeadCell>
                        <Table.HeadCell>Role</Table.HeadCell>
                        <Table.HeadCell>Permissions</Table.HeadCell>
                        <Table.HeadCell>Payment ID</Table.HeadCell>
                        <Table.HeadCell>Profile status</Table.HeadCell>
                        <Table.HeadCell>Payment ID Verification</Table.HeadCell>
                        <Table.HeadCell>Edit Permission</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {UsersToDisplay.length > 0 &&
                            UsersToDisplay.map((user, index) => {
                                return (
                                    <Table.Row
                                        key={index}
                                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                            {user.name}
                                        </Table.Cell>
                                        <Table.Cell>{user.usn}</Table.Cell>
                                        <Table.Cell>{user.email}</Table.Cell>
                                        <Table.Cell>{user.phone}</Table.Cell>
                                        <Table.Cell>{user.role}</Table.Cell>
                                        <Table.Cell>
                                            {user.hasAccessTo}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {user.paymentID}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {user.isProfileComplete}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {user.paymentStatus ===
                                                'PENDING' && (
                                                <button
                                                    className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                                    href="/tables"
                                                    data-userid={user.userID}
                                                    onClick={(e) => {
                                                        verifyPayment(
                                                            e.target.dataset
                                                                .userid
                                                        )
                                                    }}
                                                >
                                                    {user.paymentStatus ===
                                                    'PENDING'
                                                        ? 'Click to Verify'
                                                        : 'Verified'}
                                                </button>
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <button
                                                className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                                href="/tables"
                                                data-userid={user.userID}
                                                data-name={user.name}
                                                data-role={user.role}
                                                data-permission={
                                                    user.hasAccessTo
                                                }
                                                data-usn={user.usn}
                                                data-email={user.email}
                                                onClick={(e) => {
                                                    handleEdit(e)
                                                }}
                                            >
                                                Edit Permissions
                                            </button>
                                        </Table.Cell>
                                        <TableCell>
                                            <Button
                                                color="failure"
                                                onClick={() => {
                                                    deleteAccount(user.userID)
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </Table.Row>
                                )
                            })}
                        {/* {users.length > 0 && (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {Info.name}
                </Table.Cell>
                <Table.Cell>{Info.usn}</Table.Cell>
                <Table.Cell>{Info.email}</Table.Cell>
                <Table.Cell>{Info.phone}</Table.Cell>
                <Table.Cell>{Info.role}</Table.Cell>
                <Table.Cell>{Info.hasAccessTo}</Table.Cell>
                <Table.Cell>
                  {Info.isVerified ? "Verified" : "Not Verified"}
                </Table.Cell>
                <Table.Cell>
                  {!Info.isVerified && (
                    <button
                      className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                      href="/tables"
                      data-userid={Info.userID}
                      onClick={(e) => {
                        verifyUser(e.target.dataset.userid);
                      }}
                    >
                      Verify
                    </button>
                  )}
                </Table.Cell>
              </Table.Row>
            )} */}
                    </Table.Body>
                </Table>
                {showEditModal && (
                    <EditUserPermission
                        props={{
                            data: {
                                Name,
                                Usn,
                                Email,
                                Role,
                                Permission,
                                UserID,
                            },
                            setshowEditModal,
                            showEditModal,
                        }}
                    />
                )}
            </div>
            {Message && <Messagepopup message={Message} />}
        </>
    )
}
