import React from 'react'
import { Card } from 'flowbite-react'
import { TypeAnimation } from 'react-type-animation'
import NavigationComponent from './Nav_footer/Navigation'
import { FooterBrand } from 'flowbite-react/lib/esm/components/Footer/FooterBrand'
import FooterComponent from './Nav_footer/Footer'
import DriveCards from './Cards/DriveCard'

export default function Resourse() {
    return (
        <>
            <NavigationComponent />
            {/* <div className="bg-gradient-to-br from-teal-200 via-violet-600 to-teal-500 ">
                <div className="h-52 "></div>
                <h1 className=" h-96 text-center align-middle text-white hover:text-slate-50 underline decoration-pink-500  text-4xl font-extraboldmd:text-5xl lg:text-6xl">
                    <TypeAnimation
                        sequence={[
                            'Peruse Our Reference Page for Valuable Sources to Aid Your Investigation',
                        ]}
                        wrapper="span"
                        speed={50}
                        style={{
                            fontSize: '1em',
                            display: 'inline-block',
                        }}
                        omitDeletionAnimation={true}
                        cursor={false}
                    />
                </h1>
                <h2 className="text-amber-50 text-center  text-5xl font-mono">
                    Resourse
                </h2>
                <div className="grid grid-rows-6 grid-flow-col gap-5 p-10 min-h-fit ">
                    <div className="row-start-1 row-span-3 min-w-fit mt-auto transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-90 duration-300">
                        <Card horizontal imgSrc="/images/blog/image-4.jpg">
                            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                <p>Noteworthy technology acquisitions 2021</p>
                            </h5>
                            <p className="font-normal text-gray-700 dark:text-gray-400">
                                <p>
                                    Here are the biggest enterprise technology
                                    acquisitions of 2021 so far, in reverse
                                    chronological order.
                                </p>
                            </p>
                        </Card>
                    </div>
                    <div className="row-start-2 row-span-3 min-w-fit mt-auto transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-90 duration-300">
                        <Card horizontal imgSrc="/images/blog/image-4.jpg">
                            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                <p>Noteworthy technology acquisitions 2021</p>
                            </h5>
                            <p className="font-normal text-gray-700 dark:text-gray-400">
                                <p>
                                    Here are the biggest enterprise technology
                                    acquisitions of 2021 so far, in reverse
                                    chronological order.
                                </p>
                            </p>
                        </Card>
                    </div>
                    <div className="row-start-1 row-span-3 min-w-fit mt-auto transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-90 duration-300">
                        <Card horizontal imgSrc="/images/blog/image-4.jpg">
                            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                <p>Noteworthy technology acquisitions 2021</p>
                            </h5>
                            <p className="font-normal text-gray-700 dark:text-gray-400">
                                <p>
                                    Here are the biggest enterprise technology
                                    acquisitions of 2021 so far, in reverse
                                    chronological order.
                                </p>
                            </p>
                        </Card>
                    </div>
                </div>
                <h2 className="text-amber-50 text-center text-5xl font-mono">
                    Resourse
                </h2>
                <div className="grid grid-rows-6 grid-flow-col gap-5 p-10 min-h-fit  ">
                    <div className="row-start-1 row-span-3 min-w-fit mt-auto transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-90 duration-300">
                        <Card horizontal imgSrc="/images/blog/image-4.jpg">
                            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                <p>Noteworthy technology acquisitions 2021</p>
                            </h5>
                            <p className="font-normal text-gray-700 dark:text-gray-400">
                                <p>
                                    Here are the biggest enterprise technology
                                    acquisitions of 2021 so far, in reverse
                                    chronological order.
                                </p>
                            </p>
                        </Card>
                    </div>
                    <div className="row-start-2 row-span-3 min-w-fit mt-auto transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-90 duration-300">
                        <Card horizontal imgSrc="/images/blog/image-4.jpg">
                            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                <p>Noteworthy technology acquisitions 2021</p>
                            </h5>
                            <p className="font-normal text-gray-700 dark:text-gray-400">
                                <p>
                                    Here are the biggest enterprise technology
                                    acquisitions of 2021 so far, in reverse
                                    chronological order.
                                </p>
                            </p>
                        </Card>
                    </div>
                    <div className="row-start-1 row-span-3 min-w-fit mt-auto transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-90 duration-300">
                        <Card horizontal imgSrc="/images/blog/image-4.jpg">
                            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                <p>Noteworthy technology acquisitions 2021</p>
                            </h5>
                            <p className="font-normal text-gray-700 dark:text-gray-400">
                                <p>
                                    Here are the biggest enterprise technology
                                    acquisitions of 2021 so far, in reverse
                                    chronological order.
                                </p>
                            </p>
                        </Card>
                    </div>
                </div>
                <h2 className="text-amber-50 text-center text-5xl font-mono">
                    Resourse
                </h2>
                <div className="grid grid-rows-6 grid-flow-col gap-5 p-10 min-h-fit  ">
                    <div className="row-start-1 row-span-3 min-w-fit mt-auto transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-90 duration-300">
                        <Card horizontal imgSrc="/images/blog/image-4.jpg">
                            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                <p>Noteworthy technology acquisitions 2021</p>
                            </h5>
                            <p className="font-normal text-gray-700 dark:text-gray-400">
                                <p>
                                    Here are the biggest enterprise technology
                                    acquisitions of 2021 so far, in reverse
                                    chronological order.
                                </p>
                            </p>
                        </Card>
                    </div>

                    <div className="row-start-2 row-span-3 min-w-fit mt-auto transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-90 duration-300">
                        <Card horizontal imgSrc="/images/blog/image-4.jpg">
                            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                <p>Noteworthy technology acquisitions 2021</p>
                            </h5>
                            <p className="font-normal text-gray-700 dark:text-gray-400">
                                <p>
                                    Here are the biggest enterprise technology
                                    acquisitions of 2021 so far, in reverse
                                    chronological order.
                                </p>
                            </p>
                        </Card>
                    </div>
                    <div className="row-start-1 row-span-3 min-w-fit mt-auto transition ease-in-out delay-150 hover:-translate-y-1  hover:scale-90 duration-300">
                        <Card horizontal imgSrc="/images/blog/image-4.jpg">
                            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                <p>Noteworthy technology acquisitions 2021</p>
                            </h5>
                            <p className="font-normal text-gray-700 dark:text-gray-400">
                                <p>
                                    Here are the biggest enterprise technology
                                    acquisitions of 2021 so far, in reverse
                                    chronological order.
                                </p>
                            </p>
                        </Card>
                    </div>
                </div>
            </div> */}
            {/* <div className="grid grid-rows-4">
                <div className="grid grid-rows-1 mt-4 mb-3 gap-3">
                    <div className="flex py-5 lg:px-20 md:px-10 px-5 lg:mx-40 md:mx-20 mx-5 font-bold text-4xl text-gray-800">
                        WorkShop 1
                    </div>
                    <div className="flex overflow-x-scroll pb-10">
                        <div className="inline-block px-3 ">
                            <div className="flex space-x-4 gap-2">
                                <DriveCards />
                                <DriveCards />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-rows-1 mt-4 mb-3 gap-3">
                    <div className="flex py-5 lg:px-20 md:px-10 px-5 lg:mx-40 md:mx-20 mx-5 font-bold text-4xl text-gray-800">
                        WorkShop 2
                    </div>
                    <div className="flex overflow-x-scroll pb-10">
                        <div className="inline-block px-3 ">
                            <div className="flex space-x-4 gap-2">
                                <DriveCards />
                                <DriveCards />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-rows-1 mt-4 mb-3 gap-3">
                    <div className="flex py-5 lg:px-20 md:px-10 px-5 lg:mx-40 md:mx-20 mx-5 font-bold text-4xl text-gray-800">
                        WorkShop 3
                    </div>
                    <div className="flex overflow-x-scroll pb-10">
                        <div className="inline-block px-3 ">
                            <div className="flex space-x-4 gap-2">
                                <DriveCards />
                                <DriveCards />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-rows-1 mt-4 mb-3 gap-3">
                    <div className="flex py-5 lg:px-20 md:px-10 px-5 lg:mx-40 md:mx-20 mx-5 font-bold text-4xl text-gray-800">
                        WorkShop 4
                    </div>
                    <div className="flex overflow-x-scroll pb-10">
                        <div className="inline-block px-3 ">
                            <div className="flex space-x-4 gap-2">
                                <DriveCards />
                                <DriveCards />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <h6 className="min-h-screen text-center align-middle m-auto">Course Material Not Updated</h6>
            <div><FooterComponent /></div>
        </>
    )
}
