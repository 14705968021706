import React, { useEffect, useState } from 'react'
import Messagepopup from './Popup/Messagepopup'
import EventCard from './Cards/EventCard'
import AttendacePage from '../pages/Attendacepage'
import QRScannerComponent from './Qrcode'


export default function LoginForm() {
    return(
        <>
        <QRScannerComponent/>
        </>
    )
}
