import React, { useEffect } from "react";
import { useState } from "react";
import { Tabs, Button } from "flowbite-react";
import ExpenseFrom from "../components/Forms/ExpenseForm";
import InventoryTable from "../components/Table/InventoryTable"
import NavigationComponent from "../components/Nav_footer/Navigation";
import axios from "axios";
import FooterComponent from "../components/Nav_footer/Footer";
import { useParams } from "react-router-dom";
import InventoryForm from "../components/Forms/InventoryForm";


export default function InventoryPage() {
    const params = useParams()
    const mode = params.mode
    const [ShowUploadForm, setShowUploadForm] = useState(false);
    const [List, setList] = useState([]);
    // const header = "Inventory Form"
    // const catagory = "Quantity"
    // const buttonmessage = "Add"
    // const name = "Component Name"
    // const permission = true

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/inventory/listInventory`, {}, { withCredentials: true })
            .then(result => {
                if (result.status === 200 && result.data.message === "success") {
                    setList(result.data.data)
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    return window.location.href = "/login"
                }
                if (err.response.status === 403) {
                    return window.location.href = "/"
                }
                console.log(err)
            })
    }, [])
    return (
        <>
            {ShowUploadForm && <InventoryForm props={{ ShowUploadForm, setShowUploadForm, List, setList }} />}
            <NavigationComponent />
            <div className="mt-8 my-5">

                <div className="text-center"> <h1 className="font-bold mb-10">Club Inventory Table</h1></div>
                <InventoryTable data={{ List, mode }} />
                <div>
                    <div className="flex gap-3 w-fit m-auto mt-10">

                        <div className="items-center m-1" >
                            {mode === "edit" && <Button
                                // disabled= {mode}
                                onClick={() => setShowUploadForm(true)}>
                                Add
                            </Button>}
                        </div>
                        <div className="items-center m-1">
                            {mode === "edit" && <Button
                                // disabled= {mode}
                                onClick={() => setShowUploadForm(true)}>
                                Export
                            </Button>}


                        </div>
                    </div>
                </div>

            </div>
            <div className="relative bottom-0 text-center p-8  w-full">
                <FooterComponent />
            </div>

        </>
    )
}