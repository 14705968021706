import React, { useContext, useEffect, useState } from 'react'
import { TypeAnimation } from 'react-type-animation'
import { Button } from 'flowbite-react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import axios from 'axios'
import InformationModal from './RegisterForms/InformationModal'
import { UserContext } from '../App'
import Messagepopup from './Popup/Messagepopup'

export default function LandingPage() {
    const [Message, setMessage] = useState('')
    const [showInformationModal, setshowInformationModal] = useState(false)
    const user = useContext(UserContext)
    function Emailchecker() {
        var url = new URLSearchParams(window.location.search)
        console.log(url)
        if (url.has('error')) {
            setMessage(url.get('error'))
            setTimeout(() => {
                setMessage('')
            }, 3000)
        }
    }
	useEffect(() => {
		Emailchecker();
	  }, []);

    useEffect(() => {
        if (user) {
            if (!user.isProfileComplete || !user.PaymentID) {
                setshowInformationModal(true)
            }
			else{
				window.location.replace(process.env.REACT_APP_HOME_URL)
			}
        }
    }, [])
    const SignUp = () => {
        axios
            .post(`${process.env.REACT_APP_SERVER_URL}/api/auth/register`, {
                withCredentials: true,
            })
            .then((result) => {
                if (result.data.message === 'success') {
                    window.location.replace(result.data.authUrl)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <GoogleOAuthProvider>
                <section className="bg-gradient-to-t from-sky-200 via-sky-100 to-blue-400 dark:bg-gray-900 sticky top-1/2">
                    <div className="flex self-center py-8 px-4 mx-auto min-h-screen max-w-screen-xl text-center lg:py-16 z-10 sticky top-1/2">
                        <div className="self-center">
                            <h1 className="self-center text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
                                <TypeAnimation
                                    sequence={[
                                        'Embrace Innovation: Join Robotics Club NITTE',
                                    ]}
                                    wrapper="span"
                                    speed={50}
                                    style={{
                                        fontSize: '1em',
                                        display: 'inline-block',
                                        position: 'sticky',
                                        minHeight: '8rem',
                                    }}
                                    omitDeletionAnimation={true}
                                    cursor={false}
                                />
                            </h1>
                            <p className="mb-8 text-lg font-normal text-gray-700 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-700">
                                Robotics Club Nitte, the official Robotics and
                                multidisciplinary club of NMAMIT.
                            </p>
                            <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                                <Button
                                    className="inline-flex justify-center items-center py-1 px-3 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
                                    onClick={() => {
                                        SignUp()
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="w-4.5 h-3.5 ml-2 mr-2"
                                        preserveAspectRatio="xMidYMid"
                                        viewBox="0 0 256 262"
                                        id="google"
                                    >
                                        <path
                                            fill="#4285F4"
                                            d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                                        ></path>
                                        <path
                                            fill="#34A853"
                                            d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                                        ></path>
                                        <path
                                            fill="#FBBC05"
                                            d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                                        ></path>
                                        <path
                                            fill="#EB4335"
                                            d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                                        ></path>
                                    </svg>
                                    Sign in with Google
                                </Button>
                                <a
                                    href="https://roboticsclubnitte.com/"
                                    class="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 text-base font-medium text-center text-gray-500 rounded-lg border border-blue-800 hover:bg-gray-100 focus:ring-4 focus:ring-gray-400"
                                >
                                    Learn more
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </GoogleOAuthProvider>
            {Message && <Messagepopup message={Message} />}
            <InformationModal
                props={{ showInformationModal, setshowInformationModal }}
            />
        </>
    )
}
