import React, { useEffect, useState } from "react";
import { Modal, Button, TextInput, Label, FileInput, Table, Select, Textarea } from "flowbite-react";
import Messagepopup from "../Popup/Messagepopup";
import axios from "axios";

export default function InventoryForm({ props }) {
    const [TempList, setTempList] = useState([]);
    const [Name, setName] = useState("")
    const [Catagory, setCatagory] = useState("")
    const [Quantity, setQuantity] = useState("")
    const [Message, setMessage] = useState("")
    const [Categories, setCategories] = useState([<option selected disabled>Select Category</option>])
    const [Remarks, setRemarks] = useState("")
    const [Condition, setCondition] = useState("")

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/inventory/getInventoryCategories`, {}, { withCredentials: true })
            .then(result => {
                if (result.status === 200 && result.data.message === "success") {
                    for (const key in result.data.categories) {
                        Categories.push(<option>{key}</option>)
                        setCategories([
                            ...Categories
                        ])
                    }
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    return window.location.href = "/login"
                }
                if (err.response.status === 403) {
                    return window.location.href = "/"
                }
                console.log(err)
            })
    }, [])

    const addExpense = (e) => {
        if (TempList.length == 0) {
            setMessage("Empty List!")
            return (
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            );
        }

        //Set loadin true here
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/inventory/addToInventory`, { List: TempList }, { withCredentials: true })
            .then((response) => {
                if (response.data.message === "success") {
                    setMessage("Item Added!")
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                }
            }).catch(error => {
                setMessage("An error occurred!");
                console.log(error);
                setTimeout(() => {
                    setMessage("");
                }, 1000)
            })
    }
    const addToList = (e) => {
        e.preventDefault();
        setTempList([
            ...TempList,
            {
                Name,
                Catagory,
                Quantity,
                Condition,
                Remarks,
            }
        ]);
        setMessage("Added");
        setTimeout(() => {
            setMessage("");
        }, 2000);

        document.getElementById('expense-form').reset();
    }

    return (<>
        <React.Fragment>
            <Modal
                show={props.ShowUploadForm}
                size="lg"
                root={document.body}
                onClose={() => props.setShowUploadForm(false)}
            >
                <Modal.Header className="text-center">
                    INVENTORY Entry Form
                </Modal.Header>
                <Modal.Body>
                    <div className="overflow-auto">

                        <Table hoverable>
                            <Table.Head>
                                <Table.HeadCell>
                                    COMPONENT NAME
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    CATEGORY
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    QUANTITY
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    Condition
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    Remarks
                                </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                {TempList.map((element, index) => {
                                    return (
                                        <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                {element.Name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {element.Catagory}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {element.Quantity}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {element.Condition}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {element.Remarks}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                    <form id="expense-form" onSubmit={e => { addToList(e) }} className="flex flex-col gap-4">
                        <div>

                            <div>
                                <div className="mb-1 block">
                                    <Label
                                        htmlFor="Catagory"
                                        value="Select Catagory"
                                    />
                                    <Select
                                        id="Catagory"
                                        onChange={(e) => setCatagory(e.target.value)}
                                        required
                                        defaultValue={undefined}
                                    >
                                        {Categories}
                                    </Select>
                                </div>
                                {/* <h6 className="font-semibold">*What did you buy this for.</h6> */}
                            </div>
                            <div className="bg-green-100 p-5 ">
                                <div>
                                    <div className="mb-2 block">
                                        <Label
                                            htmlFor="COMPONENT NAME"
                                            value="COMPONENT NAME"
                                        />
                                    </div>
                                    <TextInput
                                        id="COMPONENT NAME"
                                        placeholder=""
                                        shadow={true}
                                        required
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>

                                <div>
                                    <div className="mb-2 block">
                                        <Label
                                            htmlFor="QUANTITY"
                                            value="QUANTITY"
                                        />
                                    </div>
                                    <TextInput
                                        id="QUANTITY"
                                        shadow={true}
                                        required
                                        type="number"
                                        onChange={(e) => setQuantity(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label
                                            htmlFor="Condition"
                                            value="CONDITION"
                                        />
                                    </div>
                                    <TextInput
                                        id="CONDITION"
                                        placeholder=""
                                        shadow={true}
                                        required
                                        onChange={(e) => setCondition(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label
                                            htmlFor="Remark"
                                            value="Remarks"
                                        />
                                    </div>
                                    <TextInput
                                        id="Remarks"
                                        placeholder=""
                                        shadow={true}
                                        required
                                        onChange={(e) => setRemarks(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <Button type="submit" >
                            ADD
                        </Button>
                        <Button onClick={addExpense}>
                            Submit
                        </Button>
                    </form>
                    {Message && <Messagepopup message={Message} />}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </React.Fragment >

    </>)
}