import React, { useEffect } from "react";
import { useState } from "react";
import { Dropdown, Modal, Button, Label, TextInput } from "flowbite-react";
import { Select } from "flowbite-react";
import axios from "axios";
import Messagepopup from "../Popup/Messagepopup";

export default function EditUserPermission({ props }) {
    const [NewRole, setRole] = useState(undefined)
    const [NewPermission, setPermission] = useState(undefined)
    const [RolesList, setRolesList] = useState([])
    const [PermissionsList, setPermissionsList] = useState([<option selected disabled>Select Permission</option>])
    const [Message, setMessage] = useState("")
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/getRolesAndPermissions`, {}, { withCredentials: true })
            .then(result => {
                if (result.status === 200 && result.data.message === "success") {
                    for (const key in result.data.data.permissions) {
                        PermissionsList.push(<option>{key}</option>)
                        setPermissionsList([
                            ...PermissionsList
                        ])
                    }
                    for (const key in result.data.data.roles) {
                        RolesList.push(<option>{result.data.data.roles[key]}</option>)
                        setRolesList([
                            ...RolesList
                        ])
                    }
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    return window.location.href = "/login"
                }
                if (error.response.status === 403) {
                    return window.location.href = "/"
                }
                console.log(error)
            })
    }, [])

    const updateUser = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/editUser`,
            {
                NewRole,
                newAccess: NewPermission,
                userToUpdate: props.data.UserID
            },
            { withCredentials: true }
        )
            .then(result => {
                if (result.status === 200 && result.data.message === "success") {
                    setMessage(result.data.message)
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    return window.location.href = "/login"
                }
                if (err.response.status === 403) {
                    return window.location.href = "/"
                }
                console.log(err)
            })
    }


    return (
        <>
            <Modal
                show={true}
                size="lg"
                root={document.body}
                onClose={() => props.setshowEditModal(false)}
            >
                <Modal.Body>
                    <form onSubmit={(e) => { updateUser(e) }}>
                        <div>
                            <div className="text-center">
                                <Label
                                    htmlFor="text"
                                    value="Modifying User:"
                                />
                                <h3 className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                    {`${props.data.Name}`}
                                </h3>
                                <h3 className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                    {`${props.data.Usn}`}
                                </h3>
                                <h3 className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                    {`${props.data.Email}`}
                                </h3>

                            </div>

                            <div className="max-w-md">
                                <h3 className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                    Change the Role to
                                </h3>
                                <div className="mb-1 block">
                                    <Select onChange={(e) => setRole(e.target.value)}>
                                        {RolesList}
                                    </Select>
                                </div>

                            </div>
                            <div>
                                <div className="mb-1 block">
                                    <Label
                                        htmlFor="Access"
                                        value={`Current Access:`}
                                    />
                                </div>
                                <TextInput
                                    id="Access"
                                    disabled
                                    placeholder={props.data.Permission}
                                />
                            </div>
                            <div>
                                <div className="mb-1 block">
                                    <Label
                                        htmlFor="NewAccess"
                                        value="New Access Grant"
                                    />
                                </div>
                            </div>
                            <Select onChange={(e) => setPermission(e.target.value)}>
                                {PermissionsList}
                            </Select>

                        </div>
                        <div>
                            <div className="flex justify-center gap-4 m-7">
                                <Button color="failure" type="submit">
                                    Yes, I'm sure
                                </Button>
                                <Button color="gray" onClick={() => {props.setshowEditModal(false);
                                 document.body.style.overflow = 'unset';}} >
                                    No, cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                    {Message && <Messagepopup message={Message} />}
                </Modal.Body>
            </Modal >
        </>
    )
}
