import React, { useContext, useEffect, useState } from 'react'
import QRCode from 'qrcode'
import { Card, Dropdown } from 'flowbite-react'
import DeletePopup from '../Popup/DeletePopup'
import axios from 'axios'
import { UserContext } from '../../App'

export default function Profilecomponent() {
	const [ShowDeleteModal, setShowDeleteModal] = useState(false)
	const user = useContext(UserContext)

	useEffect(() => {
		const textToEncode = JSON.stringify({
			UserId: user.UserID,
			RcnId: user.RcnID,
		})

		textToEncode
			? QRCode.toString(
					textToEncode,
					{ type: 'svg' },
					function (err, url) {
						if (err) {
							console.error(err)
							return
						}
						const parser = new DOMParser()
						const svgDoc = parser.parseFromString(
							url,
							'image/svg+xml'
						)
						const svgElement = svgDoc.documentElement
						const svgContainer =
							document.getElementById('svgContainer')
						svgContainer.innerHTML = ''
						svgContainer.appendChild(svgElement)
					}
			  )
			: console.log('No RCNID')

		//    for (const key in user) {
		//    window.sessionStorage.setItem(key, user[key]);
		//  if (key === "Events") {
		//  setEvents(user[key]);
		//} else {
		// eval(`set${key}("${user[key]}")`);
		//}
		//}
		console.log(user.ID)
	}, [user.ID])

	const logout = () => {
		axios
			.delete(`${process.env.REACT_APP_SERVER_URL}/api/auth/logout`, {
				withCredentials: true,
			})
			.then(() => {
				window.location.replace('https://roboticsclubnitte.com')
			})
	}
	return (
		<>
			{ShowDeleteModal && (
				<DeletePopup props={{ ShowDeleteModal, setShowDeleteModal }} />
			)}
			<div className="max-w-sm m-auto">
				<Card>
					<div className="flex justify-end px-4 pt-4">
						<Dropdown inline={true} label="">
							{/* <Dropdown.Item>
								<a
									href="#"
									className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
								>
									Edit Profile
								</a>
							</Dropdown.Item> */}
							<Dropdown.Item>
								<button
									className="block py-2 px-4 text-sm text-red-600 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
									onClick={() => setShowDeleteModal(true)}
								>
									Delete account
								</button>
							</Dropdown.Item>
						</Dropdown>
					</div>
					<div className="flex flex-col items-center pb-10">
						<div
							className="mb-3 h-48 w-48  shadow-lg"
							id="svgContainer"
						></div>
						<h5 className="text-center mb-1 text-xl font-medium text-gray-900 dark:text-white">
							{user.ID}
						</h5>
						<h5 className=" text-center mb-1 text-xl font-medium text-gray-900 dark:text-white">
							{user.Name}
						</h5>
						<span className="text-sm text-gray-500 dark:text-gray-400">
							ROLE: {user.Role}
						</span>
						{user.Email}

						<div className="mt-4 space-x-3 lg:mt-6">
							{(user.Permissions === 'ADMIN' ||
								user.Permissions === 'SUPERUSER') && (
								<a
									href="/admin"
									className="inline-flex items-center rounded-lg bg-blue-700 py-2 px-4 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
								>
									Admin Page
								</a>
							)}
							<button
								className="inline-flex items-center rounded-lg bg-blue-700 py-2 px-4 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
								onClick={logout}
							>
								Logout
							</button>
						</div>
					</div>
				</Card>
				{/* <div>
          <h1>Events Registered</h1>
          <h2>{Interests}</h2>
          <h2>{ID}</h2>
          <h2>{PaymentID}</h2>
          {Events.map((element, index) => {
            return (
              <div key={index}>
                <h1>{element.eventName}</h1>
                <h1>{element.eventDate?.split("T")[0]}</h1>
              </div>
            );
          })}
        </div> */}
			</div>
		</>
	)
}
