import React, { useState } from "react";
import { Modal, Button, Label, TextInput } from "flowbite-react";
import { ModalBody } from "flowbite-react/lib/esm/components/Modal/ModalBody";
import axios from "axios";
import Messagepopup from "./Messagepopup";

export default function DeletePopup({ props }) {
    console.log(props);
    const [show, setshow] = useState(false);
    const [Message, setMessage] = useState("");
    const deleteAccount = () => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/deleteAccount`, {},
        {
            withCredentials: true
        })
            .then((response) => {
                if (response.status === 200 && response.data.message === "success") {
                    setMessage(response.data.message)
                    setTimeout(() => {
                        setMessage("")
                    }, 1000);
                    window.location.reload()
                }
                else {
                    setMessage(response.data.message)
                    setTimeout(() => {
                        setMessage("")
                    }, 1000);
                }
            })
            .catch(error => {
                console.log(error);
                setMessage("An error occurred!")
                setTimeout(() => {
                    setMessage("")
                }, 1000);
            })
    }
    return (

        <>
            <Modal
                show={props.ShowDeleteModal}
                size="lg"
                root={document.body}
                onClose={() => props.setShowDeleteModal(false)}
            >
                <Modal.Body>
                    <div className="text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to delete this account?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={deleteAccount}>
                                Yes, I'm sure
                            </Button>
                            <Button color="gray" onClick={() => props.setShowDeleteModal(false)}>
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* <Modal
                show={show}
                size="lg"
                root={document.body}
            >
                <Modal.Body>
                    <div>
                        <div className="mt-2">
                            <div className="mb-2 block font-se font-mono font-black">
                                <Label
                                    htmlFor="password2"
                                    value="Enter your password to confrim"
                                />
                            </div>
                            <TextInput
                                id="password2"
                                required
                                shadow
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="flex justify-center gap-4 mt-4">
                            <Button color="failure" onClick={deleteAccount}>
                                confrim
                            </Button>
                            <Button color="gray" onClick={() => setshow(false)}>
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}
            {Message && <Messagepopup message={Message} />}
        </>
    )
}