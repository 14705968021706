import React, { useState } from "react";
import { Modal, Button, Label, TextInput } from "flowbite-react";
import { ModalBody } from "flowbite-react/lib/esm/components/Modal/ModalBody";
import axios from "axios";
import Messagepopup from "../Popup/Messagepopup";
import { Link } from "react-router-dom";

export default function PasswordPrompt({ props }) {
    console.log(props);
    const [show, setshow] = useState(false);
    const link = props.editlink
    console.log(link)

    return (

        <>
            <Modal
                show={props.showEditModal}
                size="lg"
                root={document.body}
                onClose={() => props.setshowEditModal(false)}
            >
                <Modal.Body>
                    <div className="text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to Edit  ?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" href={props.editlink} onClick={() => props.setshowEditModal(false)}>
                                Yes, I'm sure
                            </Button>
                            <Button color="gray" onClick={() => props.setshowEditModal(false)}>
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* <Modal
                show={show}
                size="lg"
                root={document.body}
            >
                <Modal.Body>
                    <div>
                        <div className="mt-2">
                            <div className="mb-2 block font-se font-mono font-black">
                                <Label
                                    htmlFor="password2"
                                    value="Enter your password to confrim"
                                />
                            </div>
                            <TextInput
                                id="password2"
                                required
                                shadow
                                type="password"
                            />
                        </div>
                        <div className="flex justify-center gap-4 mt-4">
                            <Button color="failure"  href={props.editlink}>
                                confrim
                            </Button>
                            <Button color="gray" onClick={() => setshow(false)}>
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}

        </>
    )
}