import { Flowbite, DarkThemeToggle, useTheme } from 'flowbite-react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import LoginForm from './components/Login'
import Home from './pages/Home'
import Admin from './pages/Admin'
import ExpensePage from './pages/ExpensePage'
import Iotpage from './pages/IotPage'
import InventoryPage from './pages/InventoryPage'
import DocumentationPage from './pages/DocumentaionPage'
import EventPage from './pages/EventPage'
import { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import LandingPage from './components/LandingPage'
import Messagepopup from './components/Popup/Messagepopup'
import Resourse from './components/Resourse'
import QRScannerComponent from './components/Qrcode'

export const UserContext = createContext(null)

function App() {
    const [IsLoading, setIsLoading] = useState(true)
    const [ProfilePending, setProfilePending] = useState(false)
    const [User, setUser] = useState(null)
    const [Message, setMessage] = useState('')
    const theme = useTheme()

    useEffect(() => {
        axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/api/auth/me`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    withCredentials: true,
                }
            )
            .then((result) => {
                if (result.data.message === 'success') {
                    setUser(result.data.user)
                    setIsLoading(false)
                } else if (result.data.message === 'Incomplete Profile') {
                    setIsLoading(false)
                    setProfilePending(true)
                    setUser(result.data.user)
                } else {
                    setIsLoading(false)
                    setMessage(result.data.message)
                    setTimeout(() => {
                        setMessage('')
                    }, 3000)
                }
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)
            })
    }, [])

    return (
        <>
            <div
                className={`${theme.theme.button.color.light} min-h-full overflow-y-scroll`}
            >
                <UserContext.Provider value={User}>
                    <Flowbite>
                        {!IsLoading ? (
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route
                                        path="/register"
                                        element={<LandingPage />}
                                    />
                                    <Route path='/login' element={< LoginForm />} />
                                    <Route path="/admin" element={<Admin />} />
                                    <Route path="/expenses">
                                        <Route
                                            path=":mode"
                                            element={<ExpensePage />}
                                        />
                                    </Route>
                                    <Route path="/documents">
                                        <Route
                                            path=":mode"
                                            element={<DocumentationPage />}
                                        />
                                    </Route>
                                    <Route path="/iot">
                                        <Route
                                            path=":mode"
                                            element={<Iotpage />}
                                        />
                                    </Route>
                                    <Route path="/inventory">
                                        <Route
                                            path=":mode"
                                            element={<InventoryPage />}
                                        />
                                    </Route>
                                    <Route path="/events">
                                        <Route
                                            path=":mode"
                                            element={<EventPage />}
                                        />
                                    </Route>
                                    <Route path="/resources" element={<Resourse/>}></Route>
                                    <Route path="/attandance" element={<QRScannerComponent/>}></Route>
                                </Routes>
                            </BrowserRouter>
                        ) : (
                            <h1>Loading...</h1>
                        )}
                    </Flowbite>
                </UserContext.Provider>
                {Message && <Messagepopup message={Message}></Messagepopup>}
            </div>
        </>
    )
}

export default App
