import React, { useEffect } from "react";
import { useState } from "react";
import { Tabs, Button } from "flowbite-react";
import ExpenseFrom from "../components/Forms/ExpenseForm";
import ExpenseTable from "../components/Table/ExpenseTable";
import NavigationComponent from "../components/Nav_footer/Navigation";
import axios from "axios";
import FooterComponent from "../components/Nav_footer/Footer";
import { useParams } from "react-router-dom";

export default function ExpensePage() {
    const params = useParams()
    const [ShowUploadForm, setShowUploadForm] = useState(false);
    const [List, setList] = useState([]);
    // const header = "Expense Form"
    // const catagory = "Price"
    // const buttonmessage = "Add Item"
    // const permission = false
    const mode = params.mode


    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/expenses/getExpenseByUser`, {}, { withCredentials: true })
            .then(result => {
                if (result.status === 200 && result.data.message === "success") {
                    setList(result.data.expenses)
                }
            })
    }, [])
    return (
        <>
            {ShowUploadForm && <ExpenseFrom props={{ ShowUploadForm, setShowUploadForm, List, setList}} />}
            <NavigationComponent />
            <div className="mt-8 my-5">

                <div className="text-center"> <h1 className="font-bold mb-10">Expense Table</h1></div>
                <ExpenseTable data={{ List ,mode}}></ExpenseTable>
                <div>
                    <div className="flex gap-3 w-fit m-auto mt-10">

                        <div className="items-center m-1" >
                            {mode === "edit" && <Button
                                // disabled= {mode}
                                onClick={() => setShowUploadForm(true)}>
                                Add
                            </Button>}

                        </div>
                    </div>
                </div>

            </div>
            <div className="relative bottom-0 text-center p-8  w-full">
                <FooterComponent />
            </div>

        </>
    )
}