import React, { useState, useEffect } from "react";
import { Modal, Button, Label, TextInput, Select, Textarea } from "flowbite-react";
import Messagepopup from "../Popup/Messagepopup";
import axios from "axios";

export default function EditItem({ props }) {
    const [show, setshow] = useState(false)
    const [Message, setMessage] = useState("")
    const [Name, setName] = useState(undefined)
    const [Date, setDate] = useState(undefined)
    const [Category, setCategory] = useState(undefined)
    const [Description, setDescription] = useState(undefined)
    const [MaxEntries, setMaxEntries] = useState(undefined)

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/events/verifyAccess`, {}, { withCredentials: true })
            .then(result => { return }).catch(err => {
                if (err.response.status === 401) {
                    return window.location.href = "/login"
                }
                if (err.response.status === 403) {
                    return window.location.href = "/"
                }
                console.log(err)
            })
    }, [])

    const updateEvent = (e) => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/events/editEventById`, {
            EventID: props.data.EventID,
            EventName: Name,
            Catagory: Category,
            newDate: Date,
            MaxEntries,
            Description
        }, {
            withCredentials: true
        }).then((response) => {
            if (response.status === 200 && response.data.message === "success") {
                setMessage("Event Updated")
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            }
        }
        ).catch((err) => {
            console.log(err)
            if (err.response.status === 403) {
                return window.location.replace("/")
            }
            if (err.response.status === 401) {
                return window.location.replace("/login")
            }
            setMessage("An error occured")
            setTimeout(() => {
                setMessage("");
            }, 1000)
        })
    }

    const handleDelete = () => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/events/deleteEventById`, { EventID: props.data.EventID }, {
            withCredentials: true
        }).then((response) => {
            if (response.status === 200 && response.data.message === "success") {
                setMessage("Event Deleted")
                setTimeout(() => {
                    window.location.reload()
                }, 1000);

            }
        }
        ).catch((err) => {
            console.log(err)
            if (err.response.status === 403) {
                return window.location.replace("/")
            }
            if (err.response.status === 401) {
                return window.location.replace("/login")
            }
            setMessage("An error occured")
            setTimeout(() => {
                setMessage("");
            }, 1000)
        })
    }

    return (
        <>
            <Modal
                show={show}
                size="lg"
                root={document.body}
                onClose={() => props.setshowEditModal(false)}
            >
                <Modal.Body>
                    <div className="text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Are you sure you want to Edit ?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button color="failure" onClick={() => setshow(false)}>
                                Yes, I'm sure
                            </Button>
                            <Button color="gray" onClick={() => props.setshowEditModal(false)}>
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={!show}
                size="lg"
                root={document.body}
                onClose={() => props.setshowEditModal(false)}
            >
                <Modal.Header />
                <Modal.Body>
                    <form onSubmit={(e) => { updateEvent(e) }}>
                        <div>
                            <h3 className="text-3xl mb-2 font-normal text-gray-500 dark:text-gray-400 text-center">
                                EDIT EVENT
                            </h3>

                            <div className="m-auto w-fit bg-slate-100 p-10 rounded-2xl">
                                <h3 className="mb-2">Selected Item:</h3>
                                <div>
                                    <h3>Event Name: {props.data.EventName}</h3>
                                    <h3>Category: {props.data.Catagory}</h3>
                                    <h3>Event Date: {props.data.EventDate}</h3>
                                    <h3>Description: {props.data.Desc}</h3>
                                    <h3>Max Entries: {props.data.MaxEntries}</h3>
                                </div>

                                <div className="flex justify-center mb-1 mt-2 ">
                                    <Button color="failure" onClick={handleDelete}>
                                        Delete Event
                                    </Button>
                                </div></div>

                            <div className="max-w-md mb-2 ">
                                <Label
                                    htmlFor="EVENT Name"
                                    value="Change EVENT Name"
                                />
                            </div>
                            <TextInput
                                id="Event Name"
                                defaultValue={undefined}
                                placeholder="Event Name"
                                onChange={(e) => setName(e.target.value)}
                            />
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="Category"
                                        value="Change the Category"
                                    />
                                    <Select
                                        id="Catagory"
                                        onChange={(e) => setCategory(e.target.value)}
                                        required
                                    >
                                        <option selected disabled>
                                            SELECT THE CATEGORY
                                        </option>
                                        <option >
                                            WARBOT
                                        </option>
                                        <option>
                                            IOT
                                        </option>
                                        <option>
                                            STATIONARY
                                        </option>
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor=" MEMEBERS NO."
                                        value="Max Entries"
                                    />
                                </div>
                                <TextInput
                                    id="MEMEBERS"
                                    shadow={true}
                                    defaultValue={undefined}
                                    onChange={(e) => setMaxEntries(e.target.value)}
                                />
                            </div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="DESCRIPTION "
                                    value="DISCRIPTION OF THE EVENT"
                                />
                            </div>
                            <Textarea
                                id="DESCRIPTION"
                                placeholder="DESCRIPTION"
                                defaultValue={undefined}
                                rows={4}
                                onChange={(e) => setDescription(e.target.value)}
                            />

                            <div>
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="Date"
                                        value="DATE OF THE EVENT"
                                    />
                                </div>
                                <TextInput
                                    id="DATE"
                                    shadow={true}
                                    defaultValue={undefined}
                                    type="date"
                                    onChange={(e) => setDate((e.target.value))}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-center gap-4 m-7">
                                <Button color="failure" type="submit">
                                    Save
                                </Button>
                                <Button color="gray" onClick={() => { setshow(false); props.setShowEditForm(false) }} >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                    {Message && <Messagepopup message={Message} />}
                </Modal.Body>
            </Modal >

        </>
    )
}