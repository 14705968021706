import { Button, Table } from "flowbite-react";
import { useEffect, useState } from "react";
import EditItemForm from "../Forms/EditItemForm";

export default function InventoryTable({ data }) {
    const [ItemID, setItemID] = useState("")
    const [ItemName, setItemName] = useState("")
    const [Catagory, setCatagory] = useState("")
    const [Quantity, setQuantity] = useState("")
    const [ShowEditForm, setShowEditForm] = useState(false)

    const handleEdit = (e) => {
        setItemID(e.target.dataset.id)
        setItemName(e.target.dataset.name)
        setCatagory(e.target.dataset.catagory)
        setQuantity(e.target.dataset.quantity)
        setShowEditForm(true)
    }
    return (
        <>
            <div className=" overflow-x-auto">
                <Table hoverable={true}>
                    <Table.Head>
                        <Table.HeadCell>
                            Component Name
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Category
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Quantity
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Condition
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Remarks
                        </Table.HeadCell>
                        {data.mode === "edit" && <Table.HeadCell>
                            <span className="sr-only">
                                Edit
                            </span>
                        </Table.HeadCell>}
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {data.List != null ? data.List.map((element, index) => {
                            return (
                                <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        {element.itemName}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {element.catagory}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {element.quantity}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {element.condition}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {element.remarks}
                                    </Table.Cell>
                                    {data.mode === "edit" && <Table.Cell>
                                        <button
                                            data-id={element.itemID}
                                            data-name={element.itemName}
                                            data-catagory={element.catagory}
                                            data-quantity={element.quantity}
                                            data-condition={element.condition}
                                            data-remarks={element.remarks}
                                            onClick={(e) => { handleEdit(e) }}
                                            className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                                        >
                                            Edit
                                        </button>
                                    </Table.Cell>}
                                </Table.Row>
                            )
                        }) : "Unable to get data!"}
                    </Table.Body>
                </Table>
                {ShowEditForm && <EditItemForm props={{ data: { ItemID, ItemName, Catagory, Quantity }, ShowEditForm, setShowEditForm }} />}
            </div>
        </>
    )
}