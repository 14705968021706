import React, { useState } from 'react'
import {
    Modal,
    Button,
    TextInput,
    Label,
    Table,
    Select,
    Textarea,
} from 'flowbite-react'
import Messagepopup from '../Popup/Messagepopup'
import axios from 'axios'

export default function EventForm({ props }) {
    const [TempList, setTempList] = useState([])
    const [Name, setName] = useState('')
    const [Catagory, setCatagory] = useState('')
    const [Date, setDate] = useState('')
    const [Message, setMessage] = useState('')
    const [Description, setDescription] = useState('')
    const [MaxEntries, setMaxEntries] = useState('')

    const addEvent = () => {
        if (TempList.length == 0) {
            setMessage('Empty List!')
            return setTimeout(() => {
                setMessage('')
            }, 1000)
        }

        //Set loadin true here
        axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/api/events/addEvents`,
                { List: TempList },
                { withCredentials: true }
            )
            .then((response) => {
                if (response.data.message === 'success') {
                    setMessage('Event Added!')
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
            })
            .catch((error) => {
                setMessage('An error occurred!')
                console.log(error)
                setTimeout(() => {
                    setMessage('')
                }, 1000)
            })
    }
    const addToList = (e) => {
        e.preventDefault()
        setTempList([
            ...TempList,
            {
                Name,
                Catagory,
                Date,
                MaxEntries,
                Description,
            },
        ])
        setMessage('Added')
        setTimeout(() => {
            setMessage('')
        }, 1000)

        document.getElementById('expense-form').reset()
    }
    return (
        <>
            <React.Fragment>
                <Modal
                    show={props.ShowUploadForm}
                    size="lg"
                    root={document.body}
                    onClose={() => props.setShowUploadForm(false)}
                >
                    <Modal.Header className="text-center">
                        EVENT ENTRY
                    </Modal.Header>
                    <Modal.Body>
                        <div className="overflow-auto">
                            <Table hoverable>
                                <Table.Head>
                                    <Table.HeadCell>EVENT NAME</Table.HeadCell>
                                    <Table.HeadCell>CATEGORY</Table.HeadCell>
                                    <Table.HeadCell>MAX ENTRIES</Table.HeadCell>
                                    <Table.HeadCell>EVENT DATE</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {TempList.map((element, index) => {
                                        return (
                                            <Table.Row
                                                key={index}
                                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                            >
                                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                                    {element.Name}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {element.Catagory}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {element.MaxEntries}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {element.Date}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </div>
                        <form
                            id="expense-form"
                            onSubmit={(e) => {
                                addToList(e)
                            }}
                            className="flex flex-col gap-4"
                        >
                            <div>
                                <div>
                                    <div className="mb-1 block">
                                        <Label
                                            htmlFor="Catagory"
                                            value="Select Catagory"
                                        />
                                        <Select
                                            id="Catagory"
                                            onChange={(e) =>
                                                setCatagory(e.target.value)
                                            }
                                            required
                                        >
                                            <option selected disabled>
                                                SELECT THE CATEGORY
                                            </option>
                                            <option>Web Dev</option>
                                            <option>IOT</option>
                                            <option>Modelling</option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="bg-green-100 p-5 ">
                                    <div>
                                        <div className="mb-2 block">
                                            <Label
                                                htmlFor="EVENT NAME"
                                                value="EVENT NAME"
                                            />
                                        </div>
                                        <TextInput
                                            id="EVENT NAME"
                                            placeholder=""
                                            shadow={true}
                                            required
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div className="mb-2 block">
                                            <Label
                                                htmlFor=" MEMEBERS NO."
                                                value="Max Entries"
                                            />
                                        </div>
                                        <TextInput
                                            id="MEMEBERS"
                                            shadow={true}
                                            required
                                            onChange={(e) =>
                                                setMaxEntries(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="mb-2 block">
                                        <Label
                                            htmlFor="DESCRIPTION "
                                            value="DISCRIPTION OF THE EVENT"
                                        />
                                    </div>
                                    <Textarea
                                        id="DESCRIPTION "
                                        placeholder="DESCRIPTION "
                                        required
                                        rows={4}
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                    />

                                    <div>
                                        <div className="mb-2 block">
                                            <Label
                                                htmlFor="Date"
                                                value="DATE OF THE EVENT"
                                            />
                                        </div>
                                        <TextInput
                                            id="DATE"
                                            shadow={true}
                                            required
                                            type="date"
                                            onChange={(e) =>
                                                setDate(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <Button type="submit">ADD EVENT</Button>

                            {/* <div id="fileUpload">
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="file"
                                    value="Upload file"
                                />
                            </div>
                            <FileInput
                                id="file"
                                required
                                helperText="Uplaod event Image"
                            />
                        </div>
                        <div id="fileUpload">
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="file"
                                    value="Upload file"
                                />
                            </div>
                            <FileInput
                                id="file"
                                required
                                helperText="Uplaod Rule Book"
                            />
                        </div> */}
                            <Button onClick={addEvent}>Submit</Button>
                        </form>
                        {Message && <Messagepopup message={Message} />}
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
            </React.Fragment>
        </>
    )
}
