import React, { useEffect, useState } from "react";
import { Dropdown, Navbar, Avatar, DarkThemeToggle, Button, } from "flowbite-react";
import Loadingcomponent from "../Popup/Loading";
import { NavbarLink } from "flowbite-react/lib/esm/components/Navbar/NavbarLink";
import { NavbarBrand } from "flowbite-react/lib/esm/components/Navbar/NavbarBrand";
// import { NavLink } from "react-router-dom";

export default function NavigationComponent() {
    return <>
        <Navbar
            fluid={true}
            rounded={true}
        >
            <Navbar.Brand

               href="/"
            >
                <img
                    src="/clublogo.png"
                    className="mr-3 h-6 sm:h-9 rounded-full"
                    alt="Logo"
                />
                    <span  href="/" className="self-center whitespace-nowrap text-sm font-semibold dark:text-white">
                        RoboticsClub
                    </span>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <NavbarBrand href="/" className="cursor-pointer">Dashboard</NavbarBrand>
                <DarkThemeToggle className=""></DarkThemeToggle>
            </Navbar.Collapse>
        </Navbar>


    </>
}

