import React from 'react'
import { Tabs,Card} from 'flowbite'
import NavigationComponent from '../components/Nav_footer/Navigation'
import UserTable from '../components/AdminComponent/UserTable'
import FooterComponent from '../components/Nav_footer/Footer'
import { MdDashboard } from 'react-icons/md'
import { HiClipboardList, HiUserCircle } from 'react-icons/hi'
import { Button } from 'flowbite-react'
import EditUserPermission from '../components/Forms/EditUserPermission'
import Resourse from '../components/Resourse'

export default function Admin() {
    return (
        <>
            <div>
                <NavigationComponent></NavigationComponent>
            </div>
            <div className="m-4">
                <div>
                    <h1 className="text-center font-mono font-bold">Dasboard</h1>
                </div>
            </div>
            <div className="m-4">
                <div>
                    <div className="overflow-x-auto h-auto">
                        <UserTable />
                    </div>
                </div>
            </div>
            <div>
                <FooterComponent></FooterComponent>
            </div>
            {/* <Resourse/> */}
        </>
    )
}
